import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/case-study.css';
import '../css/seo-optimization.css';
import pic1 from '../images/cs/seo/pic1.png';
import pic2 from '../images/cs/seo/pic2.png';
import pic3 from '../images/cs/seo/pic3.png';
import pic4 from '../images/cs/seo/pic4.png';
import pic5 from '../images/cs/seo/pic5.png';
import pic6 from '../images/cs/seo/pic6.png';
import arrow from '../images/arrow.svg';

const TelehealthRedesign = () => {
    useEffect(() => {
        mixpanel.track('view case study - seo optimization');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'home'}></Header>
            <div className={'case-study-root'}>
                <div className={'case-study-title'}>SEO OPTIMIZATION/BLOG RE-DESIGN</div>
                <div className={'case-study-h2'}>Background</div>
                <div className={'case-study-h3'}>Context</div>
                <div className={'case-study-paragraph'}>
                    The Fuzzy pet blog was not ranking as well as we would have liked on Google, and as a result, our organic traffic was not as high as we needed it to be. This was a major concern for the team because it meant that our target audience was not able to discover our content as easily, and we were missing out on potential traffic and engagement. We were tasked with figuring out how to improve the SEO of the blog and get it to rank higher in search results as well as increase engagement with the blog content.
                </div>
                <div className={'case-study-h3'}>Problem</div>
                <div className={'case-study-paragraph'}>
                    How might we increase SEO of the Fuzzy blog so we may increase organic traffic?
                </div>
                <div className={'case-study-h3'}>Goal</div>
                <div className={'case-study-paragraph'}>
                    Our goal was to improve the SEO of the Fuzzy blog so that we could grow organic traffic and increase the number of users interacting with our blog content. This in turn would guide these users to explore the rest of our site and gently push them towards purchasing a Fuzzy membership, which would ultimately increase the total number of members.
                </div>
                <div className={'case-study-h2'}>Getting Started</div>
                <div className={'case-study-h3'}>What does the blog page currently look like?</div>
                <div>
                    <video className={'current-ux-video'} controls >
                        <source src="./videos/seo-current-experience.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className={'case-study-h3'}>Research on blogs and SEO</div>
                <div className={'case-study-paragraph'}>
                    As my first task on the project, I focused on doing some research on SEO and Google ranking. I wanted to understand what factors influence a website's ranking on Google and how I could improve the SEO of the Fuzzy blog. I researched various strategies that could help a site rank higher in Google, such as optimizing the content, creating blog categories, and conducting keyword research. I also discovered some common mistakes that websites make that can cause them to be deranked, such as low-quality content, broken backlinks and poor site navigation
                    <br />
                    <br />
                    To study and learn about blog setup and design, I visited several different blogs and spent time exploring their layouts and features. I paid close attention to the overall appearance of the blogs, including the color scheme, font choices, and layout of the content. I also looked at how the blogs were structured, including the navigation menu and the organization of the posts. Through this process, I was able to gain a better understanding of what makes a successful blog and how to effectively design one.
                </div>
                <div className={'case-study-h2'}>Focus Areas</div>
                <div className={'case-study-h3'}>Implement breadcrumb menu</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To improve site navigation, I implemented a breadcrumb menu on our website. A breadcrumb menu is a series of links that show the user's location within the website and allow them to easily navigate back to previous pages. This can be especially helpful for users who are deep within the site and want to easily go back to a higher-level page without having to use the back button.
                        <br />
                        <br />
                        In addition to improving the user experience, a breadcrumb menu can also be beneficial for SEO. By clearly displaying the hierarchy of pages on the site, a breadcrumb menu can help search engines understand the structure of the website and how the pages are related to each other. This can improve the way the site is indexed and potentially increase its ranking in search results.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic1} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic2} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>Improve look and feel of blog</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To transform the look and feel of our blog, I applied a more modern aesthetic to the design. This involved updating the color scheme, font choices, and layout to create a visually appealing and cohesive look. I also paid attention to the functionality of the design, ensuring that all elements were placed in a logical and intuitive manner to create a clean and easy-to-use user experience. The new design helped to give the blog a fresh and modern feel, which was more in line with the overall tone of the content and provided a more engaging experience for users.
                    </div>
                    <div className={'pic-to-pic top-alignment'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic3} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic4} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>Implement optimized blog categories and Include accompanying blog posts </div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To improve the SEO of our blog, I conducted keyword research and implemented blog categories. I used the keyword research to identify popular and relevant terms that our target audience might be searching for, and used this information to strategically name the blog categories. By organizing our content into categories and using keywords in the category names, we were able to make it easier for search engines to understand the topics covered on our blog and how the content is related. This can help to improve the way our blog is indexed and potentially increase its ranking in search results.In addition to improving SEO, organizing our content into categories also made it easier for users to find and browse the content that is most relevant to them. By providing clear and intuitive navigation, we were able to improve the user experience and make it more likely that users would stay on our blog and engage with more of our content.
                        <br />
                        <br />
                        To enhance the user experience on our blog, I decided to feature the 3 most popular blog posts within each category prominently on the page, directly underneath the category heading. This allowed users to easily access and engage with the most popular content within each category, while still being able to navigate to the category page and explore additional posts if they so desired. By presenting the popular posts in this way, we could draw users' attention to the content and encourage them to read more. In addition to including the popular posts, I also included information about the read time and author for each post. This helped to create a sense of transparency and credibility for our content. Based on my research, I learned that these types of details can be persuasive for users and help to increase engagement with our content.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic5} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic6} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h2'}>Final Designs (Desktop and Mobile Web)</div>
                <div className={'video-side-by-side'}>
                    <video className={'final-left'} width="100%" controls >
                        <source src="./videos/seo-final-left.mp4" type="video/mp4"/>
                    </video>
                    <video width="100%" className={'final-right'} controls >
                        <source src="./videos/seo-final-right.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className={'case-study-h2'}>Results</div>
                <div className={'case-study-paragraph'}>
                    Although we saw an 18% increase in user engagement for users who made it to our blog, we were disappointed to find that our SEO did not increase as expected after the release of the blog redesign. Upon further investigation, we discovered that several technical issues were causing problems with our SEO, including too many API calls per page load, broken JavaScript, broken backlinks, and improper canonical URL structures. These issues were outside of the control of design and product and had a negative impact on our SEO performance. However, we are confident that once these issues are addressed by our engineering team, our SEO will improve and we will see the results we were hoping for.
                </div>
                <div className={'case-study-h2'}>In Hindsight</div>
                <div className={'case-study-paragraph'}>
                    In hindsight, it would have been much more effective to prioritize identifying and addressing the underlying issues that were negatively impacting our SEO rankings, rather than focusing on design and engineering solutions. These real and main issues could have been fixed through targeted engineering efforts, and addressing them would likely have had a greater impact on improving our search engine rankings. That being said, the updated designs we implemented did ultimately contribute to an increase in engagement with the blog, so there were still some benefits to the approach we took. Ultimately, it's important to carefully consider all factors and take a holistic approach to addressing issues with SEO and website performance.
                </div>
            </div>
            <Footer selected={'home'} />
        </div>
    )
}

export default TelehealthRedesign;