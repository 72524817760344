import '../css/portfolio-item.css'
import {useState} from "react";
import { useNavigate } from 'react-router-dom';

function PortfolioItem({url, hover, image, password}) {
    const [hovering, setHovering] = useState(false);
    const navigate = useNavigate();

    const navigateAndScroll = (url) => {
        if (password) {
            const inputPass = prompt('Please enter the password');

            if (inputPass !== 'umbrella') {
                alert('Incorrect');
                return;
            }
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
        navigate(url);
    }

    return (
        <div
            onMouseEnter={setHovering.bind(this, true)}
            onMouseLeave={setHovering.bind(this, false)}
        >
            <div className={'portfolio-item'} onClick={navigateAndScroll.bind(this, url)}>
                <img className={`portfolio-image ${ (password && !hovering) ? 'blur': ''}`} src={ hovering ? hover: image}/>
            </div>
        </div>
    );
}

export default PortfolioItem;
