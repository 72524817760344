import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/contact.css'
import {Link} from "react-router-dom";

function Error404() {
    useEffect(() => {
        mixpanel.track('view Error404');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'Error404'}></Header>
            <br/>
            <br/>
            <br/>
            <div className={'lets-talk'}>
                404. Try another page.
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Footer />
        </div>
    );
}

export default Error404;
