import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import logo from '../images/logo.png';
import logoWhite from '../images/logo-white.png';
import '../css/header.css';

function Header({selected, white = null}) {
    const navigate = useNavigate();

    const gotoResume = () => {
        mixpanel.track('view resume');
        window.open('/Michelle Malik - Resume.pdf', '_blank');
    };

    return (
        <div className={'w-full'}>
            <div className={'logo-container-small'}>
                <img
                    width={85}
                    height={85}
                    src={white ? logoWhite: logo}
                    className="header-logo"
                    alt="logo"
                    onClick={navigate.bind(this, '/')}
                />
            </div>
            <div className="header">
                <div className={'header-side'}>
                    <div className={`header-side-item ${white?'white':''} ${selected === 'home' ? 'selected': ''}`} onClick={navigate.bind(this, '/')}>WORK</div>
                    <div className={`header-side-item ${white?'white':''} ${selected === 'about' ? 'selected': ''}`} onClick={navigate.bind(this, '/about')}>ABOUT</div>
                </div>
                <div>
                    <img
                        width={150}
                        height={150}
                        src={white ? logoWhite: logo}
                        className="header-logo"
                        alt="logo"
                        onClick={navigate.bind(this, '/')}
                    />
                </div>
                <div className={'header-side'}>
                    <div className={`header-side-item ${white?'white':''}`} onClick={gotoResume}>RESUME</div>
                    <div className={`header-side-item ${white?'white':''} ${selected === 'contact' ? 'selected': ''}`}  onClick={navigate.bind(this, '/contact')}>CONTACT</div>
                </div>
            </div>
            <div className="header-small">
                <div className={`header-side-item ${white?'white':''} ${selected === 'home' ? 'selected': ''}`} onClick={navigate.bind(this, '/')}>WORK</div>
                <div className={`header-side-item ${white?'white':''} ${selected === 'about' ? 'selected': ''}`} onClick={navigate.bind(this, '/about')}>ABOUT</div>
                <div className={`header-side-item ${white?'white':''}`} onClick={gotoResume}>RESUME</div>
                <div className={`header-side-item ${white?'white':''} ${selected === 'contact' ? 'selected': ''}`}  onClick={navigate.bind(this, '/contact')}>CONTACT</div>
            </div>
        </div>
    );
}

Header.propTypes = {
    selected: PropTypes.string.isRequired
}

export default Header;
