import PropTypes from 'prop-types';
import '../css/recommendation.css';

import linkedIn from '../images/linkedin.svg';
import {useRef} from "react";
import useOnScreen from "../hooks/useOnScreen";

const Recomendation = ({name, title, image, review, url}) => {
    const ref1 = useRef(null);
    const isInView1 = useOnScreen(ref1);
    const gotoUrl = () => {
        window.location.href = url;
    }

    return (
        <div className={'recommendation-card project-fade-in'} ref={ref1} onClick={gotoUrl}>
            <img className={'linked-in'} src={linkedIn} />
            <div className={'rec-layer-1'}>
                <img className={'rec-img'} src={image}/>
                <div className={'rec-title-name'}>
                    <div className={'rec-name'}>{name}</div>
                    <div className={'rec-title'}>{title}</div>
                </div>
            </div>
            <div className={'rec-body-text'}>
                {review}
            </div>
        </div>
    );
}

Recomendation.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    review: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default Recomendation;