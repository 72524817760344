import { useEffect, useState } from 'react';

const useOnScreen = (ref, options = {}) => {
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const observerCallback = (entries) => {
            const entry = entries[0];
            setIsInView(entry.isIntersecting);
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                observer.unobserve(entry.target);
            }
        };

        const observer = new IntersectionObserver(observerCallback, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, options]);

    return isInView;
}

export default useOnScreen;
