import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/case-study.css';
import '../css/pet-care-plan.css';
import pic1 from '../images/cs/pet-care/pic1.png';
import pic2 from '../images/cs/pet-care/pic2.png';
import pic3 from '../images/cs/pet-care/pic3.png';
import pic4 from '../images/cs/pet-care/pic4.png';
import pic5 from '../images/cs/pet-care/pic5.png';
import pic6 from '../images/cs/pet-care/pic6.png';
import pic7 from '../images/cs/pet-care/pic7.png';
import pic8 from '../images/cs/pet-care/pic8.png';
import pic9 from '../images/cs/pet-care/pic9.png';
import pic10 from '../images/cs/pet-care/pic10.png';
import pic11 from '../images/cs/pet-care/pic11.png';
import pic12 from '../images/cs/pet-care/pic12.png';
import arrow from '../images/arrow.svg';

const PetCarePlan = () => {
    useEffect(() => {
        mixpanel.track('view case study - pet care plan');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'home'}></Header>
            <div className={'case-study-root'}>
                <div className={'case-study-title'}>Pet Care Plan Recommendation Design</div>
                <div className={'case-study-h2'}>Background</div>
                <div className={'case-study-h3'}>Context</div>
                <div className={'case-study-paragraph'}>
                    In Q3 of 2022, Fuzzy launched a feature called “care plans” within the native app.The team monitored engagement after the feature was released and found that it was lower than anticipated. This was not entirely a surprise given the short timeframe for planning, design, and release. It was evident that we needed further iteration based on user feedback.
                    <br />
                    <br />
                    As a new member of the engagement team, I was responsible for conducting the discovery, design, and testing process for the iteration of the recommendation portion of the pet care plans feature. These personalized recommendations for preventive and acute pet care, covering a range of categories including nutrition, dental health, mobility and behavior provide users with tasks they need to complete in order to care for their pet.
                </div>
                <div className={'case-study-h3'}>Problem</div>
                <div className={'case-study-paragraph'}>
                    How might we improve the recommendations experience in order to increase engagement and provide the best user experience to Fuzzy members?
                </div>
                <div className={'case-study-h3'}>Goal</div>
                <div className={'case-study-paragraph'}>
                    One of the primary objectives for this quarter was to improve the pet care plans feature in order to increase user engagement and provide more value to our users. We understood that higher engagement would not only give us the opportunity to gather more feedback and user insight, which would allow us to continuously discover, implement, and iterate to provide the care pet parents need for their pets but also increase our revenue.
                    <br />
                    <br />
                    After some brainstorming, we identified that the recommendations aspect of the feature may be causing friction. In order to address this issue and enhance the recommendations experience, we needed to analyze experience from the perspective of the user and identify and address any pain points or areas that may be lacking.
                </div>
                <div className={'case-study-h2'}>Getting Started</div>
                <div className={'case-study-h3'}>What is the current user experience?</div>
                <div>
                    <video className={'pet-care-current-ux-video'} controls >
                        <source src="./videos/pet-care-plan-current-experience.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className={'case-study-h3'}>User Research</div>
                <div className={'case-study-paragraph'}>
                    My first step was to conduct unmoderated user interviews with users. A fellow designer and I tested our current recommendation experience with 10 participants (current pet owners, ages 22-65, with a high school diploma or higher, making at least $25,000 per year) to evaluate their perceptions of the care plan experience overall and the recommendations experience specifically. Participants were asked questions such as, "If your pet was dealing with issue X and a vet provided you with this set of recommendations, do you feel they’d be helpful?" and "Is there anything you do or do not like about the recommendations?" and "How likely would you be to complete a recommendation?” and were given the opportunity to provide open feedback at the end of the interview. This process revealed several pain points as we suspected it would given the user engagement.
                </div>
                <div className={'case-study-h3'}>Pain points</div>
                <div className={'case-study-paragraph'}>
                    User uninformed on significance or importance
                    <ul>
                        <li>
                            Users wanted to know why they should be following specific recommendation and why they were important as opposed to following blindly without any knowledge of how the recommendation might help their pet
                        </li>
                    </ul>
                    <br />
                    UI is overwhelming to the user
                    <ul>
                        <li>
                            Users felt very overwhelmed by all the actions they felt like could take for any given recommendation. They could set up a reminder to complete the task, mark the task as completed, read more details, mark the recommendation as unhelpful or helpful, skip the recommendation and even purchase a product within the recommendation.
                        </li>
                        <li>
                            Users were overwhelmed by all the recommendations and information being all together on one screen. It felt like an overload and in a sense paralyzed them into not taking action.
                        </li>
                    </ul>
                    <br />

                    Forcing user to buy a single product/our product
                    <ul>
                        <li>
                            Users disliked the lack of product choice and felt misled by the "More options" button, which only allowed them to choose the quantity of a single product. Some users also felt pressure to buy from us rather than exploring alternative options.
                        </li>
                    </ul>
                    <br />


                    Can’t start a recommendation if you don’t have a product
                    <ul>
                        <li>
                            Users were confused about when to mark a recommendation as completed, either when purchasing the product or after receiving and using it. They also questioned how to track the recommendation timeline if they did not receive the product on the same day as the recommendation was populated.
                        </li>
                    </ul>
                    <br />
                    Product detail specifics missing
                    <ul>
                        <li>
                            Users desired more information, such as ingredients, before purchasing a product and felt unable to make a decision without knowing what the product was.
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h3'}>Competitive Research</div>
                <div className={'case-study-paragraph'}>
                    After identifying key areas of concern, I realized we’d likely need to move to a different design approach such as a nested view. I explored other apps and Dribbble inspiration in order to learn how other nested views are designed.
                </div>
                <div className={'case-study-h2'}>Focus Areas</div>
                <div className={'case-study-h3'}>Simplify UI of recommendation set and recommendation</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To improve the effectiveness of the recommendations, I simplified the design by removing functions that were not valuable to users based on my analysis of user interviews. The "helpful/unhelpful" and "read more" functions were removed, while the "mark as completed" and "set up reminder" functions were preserved. This simplification of the design, eliminating unnecessary functions, makes it more likely for users to complete the recommendation as it reduces information and action overload and allows them to focus on only the most important actions.
                        <br />
                        <br />
                        In order to improve the user experience, I removed all of the detailed information and actions from the recommendation cards themselves and implemented a nested view for each recommendation. This means that when users are looking at the overall view of all of their recommendations, they will only see the recommendation title. If they want to view all of the important details and actions for a specific recommendation, they can simply click on the recommendation card to expand it and view the additional information. This allows users to focus on one recommendation at a time rather than being overwhelmed by all of the information and actions displayed on the recommendation cards simultaneously. Overall, this change should make it easier for users to process and take action on their recommendations.
                    </div>
                    <div className={'pic-to-pic top-alignment'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic1} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic2} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic3} />
                        </div>
                    </div>
                </div>

                <div className={'case-study-h3'}>Inform user of recommendation importance</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To improve understanding and engagement with the recommendations, I added a "why this is important" card within the nested view of the design. This card allows users to read an explanation of why it is important for their pet's health and well-being to complete the recommendation. By providing this context, users can have a solid understanding of the rationale behind the recommendation and be more motivated to engage with it and complete it.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic4} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic5} />
                        </div>
                    </div>
                </div>

                <div className={'case-study-h3'}>Seamless experience for product recommendations</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To improve the usability and clarity of the recommendations, I transformed the recommendation user interface from a stack of overloaded cards to simple title-only recommendations with nested views. The titles clearly convey the recommendation, and the nested views provide access to important details and actions related to the recommendation. This transformation reduces visual clutter and overload and helps users more easily take action on the recommendations.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic6} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic7} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic8} />
                        </div>
                    </div>
                </div>


                <div className={'case-study-h3'}>Provide user with other purchasing options</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To address user frustration with the lack of product choice, I added a product carousel to the nested view design. This carousel allows users to explore all of the product options that the vet has recommended as potentially useful for accomplishing the recommendation goal, rather than being limited to a single option. By providing a range of options, users are able to make a more informed decision about which product is the best fit for their pet and their needs.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic9} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic10} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>Provide details for informed purchasing decisions</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To provide users with the information they need to make an informed decision about which product is the best fit for their pet, I transformed the product card user interface to incorporate more detailed information. This includes a description of the product, a list of ingredients, and any general vet guidance regarding the product. By including this information on the product card, users are able to make a more confident and informed decision about whether the product is a good fit for their pet.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic11} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic12} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h2'}>Final Designs (Desktop and Mobile web)</div>
                <div>
                    <video className={'pet-care-current-ux-video'} controls >
                        <source src="./videos/pet-care-plan-final.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className={'case-study-h2'}>Usability testing</div>
                <div className={'case-study-paragraph'}>
                    We were unable to test with users so to ensure that the redesigned recommendation and product interfaces would be effective and meet the needs of our users, I internally tested the design with cross-functional partners within the company. The testing was successful, and the feedback was positive. The simplified approach was seen as reducing overload for our customers and making it easier for them to complete the recommendations and engage more with the care plan. The new design was also viewed as more in line with competitors and industry standards. Overall, the testing indicated that the redesigned interfaces would be well received by users.
                </div>
                <div className={'case-study-h2'}>Business Constraints and Results</div>
                <div className={'case-study-paragraph'}>
                    The product design was approximately 8 weeks ahead of engineering and was scheduled to be built in Q1 of 2023. Initially, it was expected that the design would be released in Q4, but due to shifting priorities, the release was delayed. Despite the delay, the team is working diligently to ensure that the design is implemented as efficiently as possible and is able to meet the needs of our users.
                </div>
                <div className={'case-study-h2'}>In Hindsight</div>
                <div className={'case-study-paragraph'}>
                    In hindsight, I would have explored more visual design options for the nested view. There are many different approaches that could have been taken to design this view, and given the tight deadline, I may have converged on a solution too quickly. I realize now that it would have been beneficial to spend more time exploring different visual design options and considering how they would work within the overall design of the product. This could have helped to create more cohesive and visually appealing design options for the nested view.
                </div>
            </div>
            <Footer selected={'home'} />
        </div>
    )
}

export default PetCarePlan;