import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/case-study.css';
import '../css/sendsio.css';
import pic1 from '../images/cs/sendsio/pic1.png';
import pic2 from '../images/cs/sendsio/pic2.png';
import pic3 from '../images/cs/sendsio/pic3.jpeg';
import pic4 from '../images/cs/sendsio/pic4.png';
import pic5 from '../images/cs/sendsio/pic5.png';
import pic6 from '../images/cs/sendsio/pic6.png';
import pic7 from '../images/cs/sendsio/pic7.png';
import pic8 from '../images/cs/sendsio/pic8.png';
import pic9 from '../images/cs/sendsio/pic9.png';
import pic10 from '../images/cs/sendsio/pic10.png';
import pic11 from '../images/cs/sendsio/pic11.png';
import pic12 from '../images/cs/sendsio/pic12.png';
import pic13 from '../images/cs/sendsio/pic13.png';
import pic14 from '../images/cs/sendsio/pic14.png';
import pic15 from '../images/cs/sendsio/pic15.png';
import pic16 from '../images/cs/sendsio/pic16.png';
import pic17 from '../images/cs/sendsio/pic17.png';
import gif1 from '../images/cs/sendsio/gif1.gif';
import gif2 from '../images/cs/sendsio/gif2.gif';
import gif3 from '../images/cs/sendsio/gif3.gif';
import gif4 from '../images/cs/sendsio/gif4.gif';
import arrow from '../images/arrow.svg';

const Sendsio = () => {
    useEffect(() => {
        mixpanel.track('view case study - sendsio');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'home'}></Header>
            <div className={'case-study-root'}>
                <div className={'case-study-title'}>Sendsio: A Cryptocurrency Transaction Platform</div>
                <div className={'case-study-h2'}>Background</div>
                <div className={'case-study-h3'}>Overview</div>
                <div className={'case-study-paragraph'}>
                    As cryptocurrencies, digital and virtual currencies, become more popular, an increasing number of people have started to transact with each other on exchanges and with wallets. At present, it can be difficult for people to transact with each other. The process is often riddled with back and forth texts, unique address exchanging, safety concerns, frustration and confusion. This is a complex problem in a relatively new financial model that requires a solution that makes transacting with others safe, quick, easy, and efficient. So, what’s the solution?
                    <br />
                    <br />
                    Sendsio provides users with a way to transact person to person rather than address to address. Users have the ability to store their cryptocurrency, make deposits and withdrawals from exchanges, and send, request and receive cryptocurrency to/from other people. Sendsio is a conceptual project that may be technologically achievable in the future as the cryptocurrency industry advances.
                </div>
                <div className={'case-study-h3'}>Problem</div>
                <div className={'case-study-paragraph'}>
                    How might we allow users to store their cryptocurrency and send, receive and request any cryptocurrency from their friends safely and easily?
                </div>
                <div className={'case-study-h2'}>The Design Process</div>
                <div className={'case-study-h3'}>Secondary Research</div>
                <div className={'case-study-paragraph'}>
                    In order to better understand the issue at hand, I began conducting secondary research to get a general idea of how people stored cryptocurrency and the different processes involved in transacting with cryptocurrency. Because cryptocurrencies are a relatively new financial concept, I knew I needed to be brought up to speed on the different apps, exchanges and trends in the crypto world. After doing an abundance of secondary research, I compiled a list of important facts and concepts that I would need to understand:
                    I discovered that:
                    <br />
                    <br />
                    <ul>
                        <li>
                            10% of the United States population owns cryptocurrency
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Cryptocurrency consumers use hot/cold wallets to store crypto or they hold it on an Exchange such as Coinbase, Binance, Kraken, Uniswap, etc.
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            To send or receive crypto, first you need a wallet, then you need to put in the public address of the recipient to send
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Any send or receive transaction appears on the blockchain and is visible to anyone with access to the internet
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            There are a multitude of exchanges, hot wallets and cold wallet available to consumers but none are compatible with all cryptocurrencies
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Cryptocurrency consumers send and receive digital currencies but can’t request an amount of crypto from someone
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h3'}>User Interviews and notable quotes</div>
                <div className={'pic-side-by-side'}>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic1} />
                    </div>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic2} />
                    </div>
                </div>
                <div className={'case-study-h3'}>Affinity and Empathy Mapping</div>
                <div className={'case-study-paragraph'}>
                    Using my secondary research and the notes taken during the user interviews, I created an affinity map using a virtual post-it method (Miro) so I could sort different pieces of information into groups and themes based on their relationship to one another. After mapping together similar frustrations and pain points, wants and needs, feelings and behaviors, it became more apparent which items were widespread problems and which were more likely one-offs.
                    <br />
                    <br />
                    I then created empathy maps so I could get an idea of who my potential end-users were. These maps externalized knowledge about the end users needs, who the end users were and aided me in formulating two user personas.
                </div>
                <div className={'pic-side-by-side'}>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic3} />
                    </div>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic4} />
                    </div>
                </div>
                <div className={'case-study-h3'}>User Personas</div>
                <div className={'case-study-paragraph'}>
                    Based on my conducted interviews and maps, I synthesized my findings and created two user personas. I was then able to identify each user persona’s goals and functions within the app. The goals and functions spoke to the need for an efficient and safe way to send, request and receive cryptocurrency to/from other people, store cryptocurrency, and maintain privacy:
                    <br />
                    <br />
                    <ul>
                        <li>
                            The Privacy-oriented User needs to be able to feel safe storing funds and sending, receiving, and requesting cryptocurrency without others having access to her unique address and be able to store any coin she wants in one place
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            The Distressed user needs to be able to feel safe storing funds and sending, receiving, and requesting cryptocurrency without the back and forth of texting/exchanging addresses and coin compatibility concerns.
                        </li>
                    </ul>
                </div>
                <div className={'pic-side-by-side'}>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic5} />
                    </div>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic6} />
                    </div>
                </div>
                <div className={'case-study-h3'}>Insights</div>
                <div className={'case-study-paragraph'}>
                    After condensing everything I’d discovered at this time in the process, it was clear what my four biggest takeaways were:
                </div>
                <div className={'centered-pic-container'}>
                    <img className={'centered-pic'} src={pic7} />
                </div>
                <div className={'case-study-h3'}>User Goals</div>
                <div className={'case-study-paragraph'}>
                    In order to create an experience that allows users to easily and efficiently use cryptocurrency to transact with other people and exchanges as well as store cryptocurrency funds, I had to determine the most important user goals. The goals I identified were to:
                    <br />
                    <br />
                    <ul>
                        <li>
                            Ensure that users can send, receive and request cryptocurrency from others without providing their unique addresses (in - app transactions)
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Provide a mobile crypto wallet compatible with any cryptocurrency so users are not limited to what they can hold in the wallet
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Ensure that transactions on the blockchain cannot be linked to user identities (blockchain transactions)
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Provide users with an easier way to transact in - app with each other using cryptocurrency without worrying they will be using a specific currency to transact with that the recipient does not own or is uninterested in.
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h3'}>User Stories and MVP</div>
                <div className={'case-study-paragraph'}>
                    I drafted a list of user stories for each persona to determine which stories were the most important in order to create a great user experience. After reviewing the stories, I narrowed down a set for each persona that produced my minimum viable product (MVP):
                    <br />
                    <br />
                    <ul>
                        <li>
                            As a privacy-oriented user, I would like to transact in - app with other people (person to person) and keep my unique addresses from being linked back to my personal identity as well as transact on the blockchain
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            As a distressed user, I would like to transact in - app with other people (person to person) using cryptocurrency and not worry about coin compatibility between the other person and myself as well as transact on the blockchain
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h2'}>Defining the Information Architecture</div>
                <div className={'case-study-h3'}>User Flows</div>
                <div className={'case-study-paragraph'}>
                    After determining my MVP, I created user flows that would give me a clear visual of how the end user would navigate throughout the screens to complete each task. I needed to make sure that the user would be able to take every step necessary to complete each task at hand so I created 4 user flows:
                    <ol>
                        <li>
                            Users can pay others with cryptocurrency in - app (see below)
                        </li>
                        <br />
                        <li>
                            Users can request cryptocurrency from others in - app
                        </li>
                        <br />
                        <li>
                            Users can fulfill requests from others in - app
                        </li>
                        <br />
                        <li>
                            Users can make deposits and withdrawals (blockchain transactions), check cryptocurrency balances, and view deposit and withdrawal history
                        </li>
                    </ol>
                </div>
                <div className={'left-aligned-pic-container'}>
                    <img className={'left-aligned-pic'} src={pic8} />
                </div>
                <div className={'case-study-h3'}>Sketches</div>
                <div className={'case-study-paragraph'}>
                    Now that I knew what user flows would be the foundation of my design, I began sketching out anything I thought would work. I studied the set up of several different mobile apps and soon I had sketches that I could work off of. In order to build a clean, skeleton frame of my screens, I designed wireframes based on the sketches I’d completed.
                </div>
                <div className={'cs-gray-box'}>
                    <div className={'pic-side-by-side'}>
                        <div className={'cs-pic-container-sbs'}>
                            <img className={'cs-pic'} src={pic9} />
                        </div>
                        <div className={'cs-pic-container-sbs'}>
                            <img className={'cs-pic'} src={pic10} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>Low Fidelity Wireframes</div>
                <div className={'case-study-paragraph'}>
                    I designed low fidelity wireframes based on the sketches I’d completed and conducted usability testing. From this, I gained constructive feedback from my test subjects that allowed me to continually iterate each flow, constantly evolving the design and ensuring I was designing solutions in the most human-centric way. The biggest takeaways were:
                    <br />
                    <br />
                    <ul>
                        <li>
                            Users were confused on where to click to input an amount with in - app transacting
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users thought confirming the amount was finishing out the in - app transaction when paying/ requesting
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users felt confirming currency when withdrawing/depositing was an unnecessary step
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users thought if they were using a specific currency to transact with someone in - app, they would receive the cryptocurrency you sent rather than the cryptocurrency of their choice
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users would want to see where the deposit/withdrawal blockchain transactions was exactly in it’s network/confirmation journey
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users wanted a QR code when withdrawing because they’re often using a laptop and can’t paste the address
                        </li>
                    </ul>
                    <br />
                    It’s important to note that it was at this point, I’d discovered a hole in my logic. When a person is receiving funds from another person, they have to be able to decide what kind of cryptocurrency they’d like to receive. This initial round of testing told me that the most important feature of my design, in app transacting, was confusing and unclear. I took the feedback I received and iterated my low fidelity wireframes to ensure the gap in logic was filled by including the ability to select the preferred currency the user would like to receive when someone pays them and all other areas of inaccessibility were corrected.
                </div>
                <div className={'cs-gray-box'}>
                    <div className={'pic-side-by-side'}>
                        <div className={'cs-pic-container-sbs'}>
                            <img className={'cs-pic'} src={pic11} />
                        </div>
                        <div className={'cs-pic-container-sbs'}>
                            <img className={'cs-pic'} src={pic12} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>High Fidelity Wireframes</div>
                <div className={'case-study-paragraph'}>
                    For my high-fidelity wireframes, I designed to solve the problem areas and confusions discovered after conducting usability testing on my initial wireframes. I also began to implement a small design system just enough to give it consistency and structure without becoming too attached to certain components or styles. It was important to start ensuring that everything was aligned properly on the grid and component spacing was appropriate.
                    <br />
                    <br />
                    I once again conducted usability testing, with the intention of making sure my wireframes displayed flows that were clear and usable and noting any problem areas in my design. The biggest takeaways were:
                    <br />
                    <br />
                    <ul>
                        <li>
                            Users thought the preferred currency was the preferred currency for all types of in - app transactions; sending, requesting, and receiving cryptocurrency.
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            User would want to see where a blockchain transaction is on the blockchain in detail not as a small in app summary
                        </li>
                    </ul>
                </div>
                <div className={'cs-gray-box'}>
                    <div className={'pic-side-by-side'}>
                        <div className={'cs-pic-container-sbs'}>
                            <img className={'cs-pic'} src={pic13} />
                        </div>
                        <div className={'cs-pic-container-sbs'}>
                            <img className={'cs-pic'} src={pic14} />
                        </div>
                    </div>
                    <div className={'pic-side-by-side'}>
                        <div className={'cs-pic-container-sbs'}>
                            <img className={'cs-pic'} src={pic15} />
                        </div>
                        <div className={'cs-pic-container-sbs'}>
                            <img className={'cs-pic'} src={pic16} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>Defining the Brand</div>
                <div className={'case-study-paragraph'}>
                    I decided to go with a bold purple and softer purple for my main brand colors. Purple conveyed what I wanted my app to represent which was security, trustworthiness, creativity, innovation, and expression. Because I was using such a bold choice for my brand color, I kept the rest of the interface neutral to ensure the interface wasn’t overstimulating to the user. I used a shade of black and several shades of grey for my header and body content, making sure the contrast of colors was accessible for all users. Additionally, I used rounded corners on all of my components to give a more cohesive and trusting appearance.
                </div>
                <div className={'left-aligned-pic-container'}>
                    <img className={'left-aligned-pic'} src={pic17} />
                </div>
                <div className={'case-study-h2'}>Features</div>
                <div className={'case-study-h3'}>Person to person in - app transactions with automatic currency conversion</div>
                <div className={'case-study-paragraph'}>
                    This feature allows users to transact in - app with other people, automatically converting the cryptocurrency you send to US Dollars, then to whichever cryptocurrency the recipient would like to receive (customizable during account set-up and in Settings), without involving anyone’s unique addresses:
                    <br />
                    <br />
                    <ul>
                        <li>
                            Users can send, request and receive funds to/from other people, not addresses, with options to make the in-app transaction visible to the other user and their friends or only visible to the two users involved
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users in - app transact with other people while the app takes care of cryptocurrency conversions in order to ensure the sender can send whichever coin they’d like and the recipient can receive the funds in whichever coin they’d like.
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users can fulfill fund requests from others
                        </li>
                    </ul>
                    <br />
                </div>
                <div className={'pic-side-by-side'}>
                    <div className={'gif-sbs'}>
                        <img className={'gif'} src={gif1} />
                    </div>
                    <div className={'gif-sbs'}>
                        <img className={'gif'} src={gif2} />
                    </div>
                </div>
                <div className={'case-study-h3'}>Blockchain transactions</div>
                <div className={'case-study-paragraph'}>
                    This feature allows users to make deposits and withdrawals from/to other apps or exchanges and store funds in the mobile crypto wallet without the user's unique address being linked back to their personal identity:
                    <br />
                    <br />
                    <ul>
                        <li>
                            Users can check their app wallet value and cryptocurrency balances
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users can make deposits and view the blockchain transaction and network confirmations on Explorer
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users can make withdrawals and view the blockchain transaction on Explorer
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users can view their deposit and withdrawal history
                        </li>
                    </ul>
                </div>
                <div className={'gif-container'}>
                    <img className={'gif'} src={gif3} />
                </div>
                <div className={'case-study-h3'}>Customization and adding friends</div>
                <div className={'case-study-paragraph'}>
                    This feature allows users to find and add friends, protect their privacy and visibility and choose a preferred currency they'd like to transact with:
                    <br />
                    <br />
                    <ul>
                        <li>
                            Users can choose any preferred currency in their settings and change it as they see fit
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users can find friends by searching their username or name
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>
                            Users can adjust a variety of settings for their personal best experience
                        </li>
                    </ul>
                </div>
                <div className={'gif-container'}>
                    <img className={'gif'} src={gif4} />
                </div>
                <div className={'case-study-h2'}>Usability Testing</div>
                <div className={'case-study-paragraph'}>
                    In order to test the product design, I built out a clickable prototype and recruited 5 test users to complete moderated usability tests consisting of a specific set of tasks to be completed. Through this first round of testing, I discovered one significant issue that three test subjects mentioned that were hindering the user from feeling the most safe withdrawing funds. I worked to solve this issue so that users would feel an absolute sense of trust in the product, which would contribute to the seamless user experience I was aiming for.
                    I addressed another smaller issue regarding person to person in - app transactions, ensuring that users could clock on a transaction they’ve had and view details such as amount, date, time, description and cryptocurrency choice. After these iterations, I felt more confident that I was providing users with a great user experience.
                </div>
                <div className={'case-study-h2'}>Project Takeaways</div>
                <div className={'case-study-paragraph'}>
                    The most important lesson I’ve learned is designing solutions for complex problems requires a proper knowledge base of the problem area so it is important to not gloss over secondary and industry research. During this project, I missed a key screen that would be necessary for the design to function properly. The only reason I discovered this was through usability testing so I’ve gained an experience-based understanding of just how important it is to test and iterate.
                    <br />
                    <br />
                    As I finish up this case study, I’ve come across a CNN article, revealing that Venmo is now becoming compatible with cryptocurrency purchasing. This reinforces to me that the cryptocurrency movement will become more and more widespread as time goes on and as adoption methods become better and smarter.
                </div>

            </div>
            <Footer selected={'home'} />
        </div>
    )
}

export default Sendsio;