import {useEffect} from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/case-study.css';
import '../css/food-with-friends.css';
import pic1 from '../images/cs/food-with-friends/pic1.png';
import pic2 from '../images/cs/food-with-friends/pic2.png';
import pic3 from '../images/cs/food-with-friends/pic3.png';
import pic4 from '../images/cs/food-with-friends/pic4.png';
import pic5 from '../images/cs/food-with-friends/pic5.png';
import pic6 from '../images/cs/food-with-friends/pic6.png';
import pic7 from '../images/cs/food-with-friends/pic7.png';
import pic8 from '../images/cs/food-with-friends/pic8.png';
import pic9 from '../images/cs/food-with-friends/pic9.png';
import pic10 from '../images/cs/food-with-friends/pic10.png';
import pic11 from '../images/cs/food-with-friends/pic11.png';
import pic12 from '../images/cs/food-with-friends/pic12.png';
import pic13 from '../images/cs/food-with-friends/pic13.png';
import pic14 from '../images/cs/food-with-friends/pic14.png';
import pic15 from '../images/cs/food-with-friends/pic15.png';
import pic16 from '../images/cs/food-with-friends/pic16.png';
import pic17 from '../images/cs/food-with-friends/pic17.png';
import pic18 from '../images/cs/food-with-friends/pic18.png';
import gif1 from '../images/cs/food-with-friends/gif-1.gif';
import gif2 from '../images/cs/food-with-friends/gif-2.gif';
import gif3 from '../images/cs/food-with-friends/gif-3.gif';
import gif4 from '../images/cs/food-with-friends/gif-4.gif';

const FoodWithFriends = () => {
    useEffect(() => {
        mixpanel.track('view case study - food with friends');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'home'}></Header>
            <div className={'case-study-root'}>
                <div className={'case-study-title'}>Food with Friends: A food plan collaboration platform</div>
                <div className={'case-study-h2'}>Background</div>
                <div className={'case-study-h3'}>Overview</div>
                <div className={'case-study-paragraph'}>
                    Every day, friend groups make plans to go out to eat together. Whether it’s celebrating a friend’s
                    birthday, eating out on New Year’s Eve, or just catching up with your friend group, it always takes
                    planning and coordination within the friend group. This process is often riddled with
                    unresponsiveness, lack of communication, indecisiveness and confusion, especially when it comes to
                    figuring out which restaurant to eat at. This is a complex problem which requires a solution that
                    makes the process more efficient, easy and fair. So, what’s the solution?
                    <br/>
                    <br/>
                    Food with Friends provides a way for friends to create food plans, input preferences and vote on a
                    restaurant pool so an equitable restaurant choice is made. Food with Friends was a conceptual
                    project completed by me over the span of 10 weeks.
                </div>
                <div className={'case-study-h3'}>Problem</div>
                <div className={'case-study-paragraph'}>
                    Friend groups struggle making food plans and making a restaurant choice decision. With the
                    miscommunications, nervousness to speak up and general indecisiveness, food plans often end up
                    getting cancelled or rescheduled when the group can’t settle on a specific place to go. How might we
                    make it easier for friend groups to make fair and decisive decisions about which restaurants to eat
                    at for group dinners?
                </div>
                <div className={'case-study-h2'}>The Design Process</div>
                <div className={'case-study-h3'}>Secondary Research</div>
                <div className={'case-study-paragraph'}>
                    Through the user interviews, I was able to understand the subjects’ pain points, frustrations and
                    needs regarding making food plans with friends and making restaurant decisions. This allowed me to
                    later formulate clear and concise design goals. To conduct consistent, unbiased user interviews, I
                    prepared a set of questions to ask. This ensures access to several sets of unique answers to the
                    same questions. I discovered that:
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            Planning can be very difficult. Identifying options, predicting outcomes, evaluating
                            outcomes and figuring out what’s best for all parties is very hard.
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            The more choices we have, the less likely we are to take action. More choices means higher
                            likelihood of decision paralysis
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            To send or receive crypto, first you need a wallet, then you need to put in the public
                            address of the recipient to send
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            There are currently no products/apps (Yelp, OpenTable, Google Maps) operating that aid in
                            making it easy to find a restaurant that satisfies everyone
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h3'}>User Interviews and notable quotes</div>
                <div className={'fwf-pic-side-by-side'}>
                    <div className={'cs-pic-container'}>
                        <img className={'cs-pic'} src={pic1}/>
                    </div>
                    <div className={'cs-pic-container'}>
                        <img className={'cs-pic'} src={pic2}/>
                    </div>
                </div>
                <div className={'case-study-h3'}>Affinity and Empathy Mapping</div>
                <div className={'case-study-paragraph'}>
                    Using the notes taken during the user interviews, I created an affinity map using a virtual post-it
                    method (Miro) so I could group together similar frustrations and pain points, wants and needs,
                    feelings and behaviors. This allowed me to see what notes were common and widespread and which were
                    more likely to be one-offs.
                    <br/>
                    <br/>
                    I then created empathy maps so I could get an idea of who my potential end-users were. These maps
                    really made it clear what specific users I would be designing for and allowed me to formulate my
                    user personas.
                </div>
                <div className={'fwf-pic-side-by-side'}>
                    <div className={'cs-pic-container'}>
                        <img className={'cs-pic'} src={pic3}/>
                    </div>
                    <div className={'cs-pic-container'}>
                        <img className={'cs-pic'} src={pic4}/>
                    </div>
                </div>
                <div className={'case-study-h3'}>User Personas</div>
                <div className={'case-study-paragraph'}>
                    Based on my conducted interviews and maps, I synthesized my findings and created three user
                    personas. I was then able to identify each user persona’s goals and functions within the app. Many
                    goals and functions spoke to the need for a cohesive way to create food plans in an organized and
                    efficient manner and eliminate the indecisiveness that accompanies making restaurant decisions.
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            The Planner Friend wants to be able to create plans, have their friends input cuisine and
                            price preferences and vote on restaurants so a decision can be made that everyone
                            contributed to
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            The Non-Planner Friend wants a say in where the group eats but doesn’t want to do much else
                            or assist with plan set up
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            The Anxious/Picky Friend wants to be able to give their preferences and opinions in a way
                            that doesn’t make them look picky or cheap
                        </li>
                    </ul>
                </div>
                <div className={'fwf-pic-side-by-side'}>
                    <div className={'cs-pic-container'}>
                        <img className={'cs-pic'} src={pic5}/>
                    </div>
                    <div className={'cs-pic-container'}>
                        <img className={'cs-pic'} src={pic6}/>
                    </div>
                    <div className={'cs-pic-container'}>
                        <img className={'cs-pic'} src={pic7}/>
                    </div>
                </div>
                <div className={'case-study-h3'}>Insights</div>
                <div className={'case-study-paragraph'}>
                    After condensing everything I’d discovered at this time in the process, it was clear that my three
                    biggest takeaways were
                </div>
                <div className={'centered-pic-container'}>
                    <img className={'centered-pic'} src={pic8}/>
                </div>
                <div className={'case-study-h3'}>User Goals</div>
                <div className={'case-study-paragraph'}>
                    In order to create an experience that allows friend groups to make inclusive and decisive decisions
                    about which restaurants to eat at for group dinners I determined the most important user goals. The
                    goals I identified were to:
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            Ensure the burden of planning and decision making doesn’t rest on one user only
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Reduce back and forth between friends about where to eat
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Ensure that every friend’s opinions and preferences are taken into account
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Improve the flow of making the restaurant decision
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h3'}>User Stories and MVP</div>
                <div className={'case-study-paragraph'}>
                    I drafted a list of user stories for each persona to determine which stories were the most critical.
                    After reviewing the stories, I narrowed down a set for each persona that would come to be my MVP.
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            As a Planner, I need to be able to create plans, invite friends and have everyone give
                            cuisine and price preferences, vote on restaurants and view a live scoreboard to know what
                            most friends want
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            As a Non-planner /Anxious Friend, I need to be able to be a part of the plan, give cuisine
                            and price preferences and vote on restaurants anonymously
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            As a General user, I want to discover new restaurants and favorite them for when I want to
                            try someplace new when I go out to eat
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h2'}>Defining the Information Architecture</div>
                <div className={'case-study-h3'}>User Flows</div>
                <div className={'case-study-paragraph'}>
                    After determining what my MVP was, I created a site map to discern which screens I would need to
                    design for, how each screen was connected and how they would all function together to make a
                    cohesive product. From my site map, I made logic flows that would give me a clear visual of how a
                    user would navigate throughout the screens to complete each task. I needed to make sure that the
                    user would be able to take every step necessary to complete each task at hand. I created three user
                    flows:
                    <ol>
                        <li>
                            Users can create food plans and invite their friends and then input their preferences, vote
                            on a restaurant pool and view the live scoreboard
                        </li>
                        <br/>
                        <li>
                            Users can browse and discover new restaurants and favorite ones they like and then browse
                            through their favorites
                        </li>
                        <br/>
                        <li>
                            Users can click on the invitation link and easily input preferences, vote on a restaurant
                            pool and view the live scoreboard (see below)
                        </li>
                    </ol>
                </div>
                <div className={'left-aligned-pic-container'}>
                    <img className={'left-aligned-pic'} src={pic9}/>
                </div>
                <div className={'case-study-h3'}>Sketches</div>
                <div className={'case-study-paragraph'}>
                    I had all these features I wanted to create and I knew what screens were needed to make that happen
                    so I started sketching out anything I thought would work and soon I had loose designs that would
                    solve the problem people seemed to be having with planning food outings and deciding where to eat.
                    In order to build a clean, skeleton frame of my screens, I designed wireframes based on the sketches
                    I’d completed.
                </div>
                <div className={'cs-gray-box'}>
                    <div className={'fwf-pic-side-by-side'}>
                        <div className={'cs-pic-container fix-top-margin'}>
                            <img className={'cs-pic'} src={pic10}/>
                        </div>
                        <div className={'cs-pic-container fix-top-margin'}>
                            <img className={'cs-pic'} src={pic11}/>
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>Low Fidelity Wireframes</div>
                <div className={'case-study-paragraph'}>
                    In order to build a clean, skeleton frame of my screens, I designed low fidelity wireframes based on
                    the sketches I’d completed. I conducted quick guerilla usability testing on my wireframes and gained
                    constructive insights from my test subjects that allowed me to continually iterate each flow,
                    constantly evolving the design and ensuring I was designing solutions in the most human-centric way.
                    The biggest takeaways were:
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            The review restaurant selections page was not only confusing, it was unnecessary
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Moving through each step of the food plan was confusing for users
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Test subjects were confused by the favorite for later button and were comparing it to a
                            super like on Tinder and didn’t know why it was on the voting page
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            When setting up a plan, they were confused by the preference options wording and which zip
                            code they should be inputting
                        </li>
                    </ul>
                    <br/>
                    I was so early in my design process that I had no trouble iterating my design until I felt it was as
                    accessible as possible and ready to be converted into High-Fidelity wireframes.
                </div>
                <div className={'cs-gray-box'}>
                    <div className={'fwf-pic-side-by-side'}>
                        <div className={'cs-pic-container fix-top-margin'}>
                            <img className={'cs-pic'} src={pic12}/>
                        </div>
                        <div className={'cs-pic-container fix-top-margin'}>
                            <img className={'cs-pic'} src={pic13}/>
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>High Fidelity Wireframes</div>
                <div className={'case-study-paragraph'}>
                    For the high-fidelity wireframes, I used my low fidelity wireframes and incorporated the feedback I
                    got from my guerilla usability testing. Additionally, I started to implement a design system and
                    used components so that I could easily change and alter portions of my design instead of altering
                    things individually. This was a huge time saver and allowed me to iterate as needed, quickly and
                    efficiently.
                </div>
                <div className={'cs-gray-box'}>
                    <div className={'fwf-pic-side-by-side'}>
                        <div className={'cs-pic-container fix-top-margin'}>
                            <img className={'cs-pic'} src={pic14}/>
                        </div>
                        <div className={'cs-pic-container fix-top-margin'}>
                            <img className={'cs-pic'} src={pic15}/>
                        </div>
                    </div>
                    <div className={'fwf-pic-side-by-side'}>
                        <div className={'cs-pic-container fix-top-margin'}>
                            <img className={'cs-pic'} src={pic16}/>
                        </div>
                        <div className={'cs-pic-container fix-top-margin'}>
                            <img className={'cs-pic'} src={pic17}/>
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3'}>Defining the Brand</div>
                <div className={'case-study-paragraph'}>
                    I used cool blues for my UI because I wanted the design to have a sense of calmness since the nature
                    of making decisions in general has been proven to be a stressful activity. I wanted Food with
                    Friends to present as calming but also fun. Additionally, a big lesson I learned while choosing
                    colors was centered around color compatibility between UI elements and food images. There aren't a
                    multitude of colors that don't clash and present a cohesive look so it's something to look out for
                    whenever a product contains a lot of food imagery. As a designer, I had to try so many different
                    color schemes until I felt I’d found one that both suited the design’s vision and the UI elements.
                    The design system I implemented when I started creating my high fidelity wireframes was extremely
                    helpful in that I was able to iterate as I went and there was minimal time waste.
                </div>
                <div className={'left-aligned-pic-container'}>
                    <img className={'left-aligned-pic'} src={pic18}/>
                </div>
                <div className={'case-study-h2'}>Features</div>
                <div className={'case-study-h3'}>Food Plan Creation and Friend Collaboration</div>
                <div className={'case-study-paragraph'}>
                    This feature allows users to create a food plan and invite their friends. The user’s and their
                    friends preferences will generate a restaurant pool that everyone can vote on after reviewing
                    restaurant details. This will produce a restaurant scoreboard that updates in real time as users
                    vote so it is clear which restaurant is in first place.
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            Host can create food plans and invite their friends
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Friends can input cuisine/price preferences and can vote on a generated pool of restaurants
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Friends can view menu items, prices and reviews of the restaurants
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            A live restaurant scoreboard will show which restaurant has the most votes
                        </li>
                    </ul>
                    <br/>
                </div>
                <div className={'fwf-pic-side-by-side'}>
                    <div className={'gif-container'}>
                        <img className={'gif'} src={gif1}/>
                    </div>
                    <div className={'gif-container'}>
                        <img className={'gif'} src={gif2}/>
                    </div>
                </div>
                <div className={'case-study-h3'}>Discover Restaurants</div>
                <div className={'case-study-paragraph'}>
                    This feature allows users to discover new restaurants and favorite restaurants they like or think
                    look yummy.
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            Users can discover new restaurants
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Ability to filter through restaurants by price and cuisine
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            View menu items, prices and reviews of restaurants
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Option to favorite appealing restaurants for later
                        </li>
                    </ul>
                </div>
                <div className={'gif-container'}>
                    <img className={'gif'} src={gif3}/>
                </div>
                <div className={'case-study-h3'}>Restaurant Favorites</div>
                <div className={'case-study-paragraph'}>
                    This feature allows users to view their favorite restaurants and when the user is looking for a
                    good place for date night, they’ll have plenty to choose from.
                    <br/>
                    <br/>
                    <ul>
                        <li>
                            View restaurants favorited from Discover feature
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            Browse favorites by category or cuisines
                        </li>
                    </ul>
                    <br/>
                    <ul>
                        <li>
                            View menu items, prices and reviews of restaurants
                        </li>
                    </ul>
                </div>
                <div className={'gif-container'}>
                    <img className={'gif'} src={gif4}/>
                </div>
                <div className={'case-study-h2'}>Usability Testing</div>
                <div className={'case-study-paragraph'}>
                    In order to test the product design, I built out a clickable prototype and recruited 5 test users to
                    complete 5 moderated usability tests. a specific set of tasks. Through this first round of testing,
                    I discovered some usability issues that were hindering the user from having a seamless and intuitive
                    user experience. I worked to solve those issues with a user-centered approach, iterating the initial
                    design and testing again with a new set of 5 test users. I found that with the second usability
                    test, users flowed through the prototype relatively easily and had no true hindrances stopping them
                    from completing each set of tasks I’d given them.
                </div>
                <div className={'case-study-h2'}>Project Takeaways</div>
                <div className={'case-study-paragraph'}>
                    The most important takeaway I’ve had is that complex problems require in depth solutions. This was a
                    difficult problem and I tried to approach it with a solution-oriented mindset. Needless to say, it
                    was tough. These things take a lot of research, planning and careful execution.
                    <br/>
                    <br/>
                    It’s easy to get so excited about moving on to the next part of a project that you rush critical
                    steps like wireframing. I've learned that spending more time on my wireframes and thinking deeply
                    about the feature specifics prevents having to double back and revisit wireframes and adjust them
                    multiple times. The design process is complex and the only thing speeding accomplishes is decreased
                    potential.
                </div>
            </div>
            <Footer selected={'home'}/>
        </div>
    )
}

export default FoodWithFriends;