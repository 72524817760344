import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/case-study.css';
import '../css/telehealth.css';
import pic1 from '../images/cs/telehealth/pic1.png';
import pic2 from '../images/cs/telehealth/pic2.png';
import pic3 from '../images/cs/telehealth/pic3.png';
import pic4 from '../images/cs/telehealth/pic4.png';
import pic5 from '../images/cs/telehealth/pic5.png';
import pic6 from '../images/cs/telehealth/pic6.png';
import pic7 from '../images/cs/telehealth/pic7.png';
import pic8 from '../images/cs/telehealth/pic8.png';
import pic9 from '../images/cs/telehealth/pic9.png';
import arrow from '../images/arrow.svg';

const TelehealthRedesign = () => {
    useEffect(() => {
        mixpanel.track('view case study - telehealth redesign');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'home'}></Header>
            <div className={'case-study-root'}>
                <div className={'case-study-title'}>TELEHEALTH QUIZ FUNNEL RE-DESIGN</div>
                <div className={'case-study-h2'}>Background</div>
                <div className={'case-study-h3'}>Context</div>
                <div className={'case-study-paragraph'}>
                    Fuzzy offers 24/7 Live Vet chat and video consults (telehealth) as one of its most impactful product offerings. Users typically search for pet issues, such as "my dog is anxious" on Google and are directed to Fuzzy's pet health quiz. After taking the quiz, users are shown a recommendation screen with two telehealth subscription options they can select in order to chat or video consult with a vet.
                    <br />
                    <br />
                    When investigating the number of people who purchased a subscription at the end of the quiz funnel, it was observed that not only was the final conversion rate metric low, but there was also significant dropoff on other areas within the funnel. Since the funnel is the primary means by which we acquire customers on the web, it was evident that we needed to address and resolve the issue.
                </div>
                <div className={'case-study-h3'}>Problem</div>
                <div className={'case-study-paragraph'}>
                    How might we increase overall conversion rates and address instances of dropoff within the telehealth quiz funnel in order to increase our total member count?
                </div>
                <div className={'case-study-h3'}>Goal</div>
                <div className={'case-study-paragraph'}>
                    One of our goals for the quarter and year was to increase our total number of members as much as possible. Gaining new members was crucial for Fuzzy's growth, revenue, and proving product market fit. Moreover, having more members allows us to gather more valuable feedback and insights. Above all, our goal at Fuzzy is to provide affordable, high-quality pet healthcare access to pet parents across the US, and increasing our member count means that we can provide that level of care to more people.
                </div>
                <div className={'case-study-h2'}>Getting Started</div>
                <div className={'case-study-h3'}>What is the current user experience?</div>
                <div>
                    <video className={'current-ux-video'} controls >
                        <source src="./videos/telehealth-current-experience.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className={'case-study-h3'}>User Research</div>
                <div className={'case-study-paragraph'}>
                    My first step was to conduct unmoderated user interviews with users. I tested our current quiz funnel with 10 participants (current pet owners, ages 22-65, with a high school diploma or higher, making at least $25,000 per year) to evaluate their perceptions of the funnel, pain points and their needs as pet owners. Participants were asked questions such as, "At first glance, how helpful do you believe this service could be to you and your pet?" and "Do you feel you've been provided with enough information to make a confident decision on purchasing a subscription?” and were given the opportunity to provide open feedback at the end of the interview. This process revealed several pain points, some of which aligned with our data and some of which did not.
                </div>
                <div className={'case-study-h3'}>Pain Points</div>
                <div className={'case-study-paragraph'}>
                    Confusion around product offering
                    <br />
                    <ul>
                        <li>
                            Users felt we didn’t acknowledge that we can help them with whatever issue they’re having and were unclear about what our service would be offering them and what kind of help we can provide to them and their pet. 7/10 participants would not continue taking the quiz and search for another option or call their vet.
                        </li>
                    </ul>
                    <br />
                    Poor perception of quiz look/feel
                    <br />
                    <ul>
                        <li>
                            7/10 users felt the quiz looked untrustworthy, scammy and fake and were questioning the credibility of Fuzzy. Some users mentioned that they knew the quiz tiles were options for them to select but that they did not look like clickable components.
                        </li>
                    </ul>
                    <br />
                    User not asked about pet concern
                    <br />
                    <ul>
                        <li>
                            10/10 were confused about whether we had the ability to help them with their pet health concern because we don’t ask them what issue their pet is having.
                        </li>
                    </ul>
                    <br />
                    Progress Bar unclear
                    <br />
                    <ul>
                        <li>
                            Users understood the progress bar would fill as soon as they completed a quiz question but could not tell how many quiz questions. 3/10 participants mentioned that they were worried the quiz was going to be too long.
                        </li>
                    </ul>
                    <br />
                    Low value perception
                    <br />
                    <ul>
                        <li>
                            8/10 users felt they didn’t understand the value of the service and weren’t sure what they were purchasing a subscription for, what that subscription would provide them and how this would help them with the issue their pet was having. 1/10 said they’d purchase the subscription if there wasn’t a free trial.
                        </li>
                    </ul>
                    <br />
                    Blindsided by app download
                    <br />
                    <ul>
                        <li>
                            Users were confused on how they would be able to chat or video consult with a vet. 8/10 participants said they would be hesitant to purchase a subscription because they weren’t given any information on how it worked.
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h3'}>Competitive Research</div>
                <div className={'case-study-paragraph'}>
                    After identifying key areas of concern, I conducted research on the design and communication strategies of comparable pet health companies. I studied the quiz funnel designs of companies such as Farmer's Dog, Chewy, Ollie, and Fetch to understand their approaches and the aesthetic qualities of their products. This research helped me gain a better understanding of industry best practices and potential areas for improvement.
                </div>
                <div className={'case-study-h2'}>Focus Areas</div>
                <div className={'case-study-h3'}>Make product offering more clear</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To make our product offering more clear and compelling, I added value propositions that highlighted the key features and benefits of our service. This included emphasizing our unlimited access to 24/7 text chat and video consults with veterinarians, as well as the ability to quickly get answers to any pet health questions from our team of experienced vets. Overall, these changes helped to better communicate the value of our product and differentiate us from competitors.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic1} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic2} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3 h3-more-padding'}>Improve perception of quiz look and feel</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To enhance the professionalism and credibility of our quiz funnel, I made several adjustments to its style, look, feel, color, and user interface design. This included updating the color scheme to reflect a more modern aesthetic, as well as refining the overall layout and visual hierarchy to improve the user experience. I also incorporated more imagery and graphics to support the credibility of our brand and increase trust among potential customers. These changes helped to elevate the overall appearance and functionality of our quiz funnel, positioning it as a trusted and valuable resource for pet owners.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic3} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic4} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3 h3-more-padding'}>Address the user’s pet health concern</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To ensure that users know that we can help with the specific pet health concern they are facing, I designed a question that asks them to identify the issue their pet is having from the options we provide. This not only helps to improve the user experience, but also increases the likelihood that users will find our quiz funnel useful and relevant to their needs.
                    </div>
                    <div className={'solo-pic'}>
                        <img className={'cs-pic'} src={pic5} />
                    </div>
                </div>
                <div className={'case-study-h3 h3-more-padding'}>Make quiz progression clear</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To help users better understand their progress and where they are in the quiz funnel, I added a question indicator to each screen. This indicator is displayed prominently at the top right of the screen, providing a clear and concise way for users to track their progress. This not only helps to keep users informed and engaged, but also reduces the potential for confusion or frustration. By knowing exactly how many questions they have completed and how many are left, users are better able to focus on answering the questions and completing the quiz.
                    </div>
                    <div className={'pic-to-pic'}>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic6} />
                        </div>
                        <div className={'cs-pic-arrow-container'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic7} />
                        </div>
                    </div>
                </div>
                <div className={'case-study-h3 h3-more-padding'}>Enhance value perception of product and provide app depiction for transparency</div>
                <div className={'cs-gray-box'}>
                    <div className={'case-study-paragraph'}>
                        To more effectively communicate the value of our service to users, I added a number of value propositions, and supporting elements throughout the quiz funnel. This included providing detailed descriptions of how our chat and video consults work, as well as showcasing the full range of pet health issues that we can help with. I also included user testimonials from Trustpilot, highlighting the positive experiences of other pet owners who have used our service. These elements work together to provide a comprehensive and compelling picture of the value of our service, helping users to understand the benefits and make informed decisions about whether our service is right for them.
                        <br />
                        <br />
                        To inform users about the steps involved in accessing our text chat and video consult services, I designed a "How it works" section with an app depiction. This section clearly explains that users will need to download our app in order to take advantage of these services. By providing this information in a clear and easy-to-understand format, we can help users understand how our service works and what they need to do to get started. This can reduce confusion and make it easier for users to make an informed decision and connect with one of our qualified veterinarians.
                    </div>
                    <div className={'pic-to-pic no-vertical-center'}>
                        <div className={'telehealth-weird-arrow-alignment'}>
                            <div className={'cs-pic-container'}>
                                <img className={'cs-pic'} src={pic8} />
                            </div>
                            <div className={'weird-arrow-container'}>
                                <img className={'cs-pic arrow'} src={arrow} />
                            </div>
                        </div>
                        <div className={'weird-arrow-container2'}>
                            <img className={'cs-pic arrow'} src={arrow} />
                        </div>
                        <div className={'cs-pic-container'}>
                            <img className={'cs-pic'} src={pic9} />
                        </div>
                    </div>
                </div>

                <div className={'case-study-h2'}>Final Designs</div>
                <div className={'video-side-by-side'}>
                    <video className={'final-left'} width="100%" controls >
                        <source src="./videos/telehealth-final-left.mp4" type="video/mp4"/>
                    </video>
                    <video width="100%" className={'final-right'} controls >
                        <source src="./videos/telehealth-final-right.mp4" type="video/mp4"/>
                    </video>
                </div>

                <div className={'case-study-h2'}>Usability Testing</div>
                <div className={'case-study-h3'}>What We Did Well</div>
                <div className={'case-study-paragraph'}>
                    <ul>
                        <li>
                            90% users said they felt the service could be valuable and would continue to take the quiz
                            <br />
                            <br />
                        </li>
                        <li>
                            90% participants said they felt the look and feel of the new design was better using words like, “more modern, legit, credible”
                            <br />
                            <br />
                        </li>
                        <li>
                            80% said they would purchase a subscription to help address a pet health issue
                            <br />
                            <br />
                        </li>
                        <li>
                            80% participants felt we could help them with their pet’s issue
                            <br />
                            <br />
                        </li>
                        <li>
                            90% participants said they felt like they understood what Fuzzy’s telehealth service was offering and what value it could provide
                            <br />
                            <br />
                        </li>
                        <li>
                            90% participants said they understand what they needed to do in order to start a text chat or video consult with a vet
                            <br />
                            <br />
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h3'}>Areas of Improvement</div>
                <div className={'case-study-paragraph'}>
                    <ul>
                        <li>
                            40% felt some of the copy in our value prop section was not helpful
                            <br />
                            <br />
                        </li>
                        <li>
                            50% of users in some way expressed interest in understanding how exactly a consult worked and the information is not available
                            <br />
                            <br />
                        </li>
                    </ul>
                </div>
                <div className={'case-study-h2'}>Improvements</div>
                <div className={'case-study-paragraph'}>
                    After receiving feedback from both users and members of the product and marketing team indicating that the value proposition sections were repetitive, we decided to revamp one of those sections to more effectively demonstrate how a consultation would work in a real-life scenario. While we were initially excited about the idea of creating a short video demonstrating a live consultation, it was ultimately determined to be infeasible. Instead, we opted for a more straightforward user interface featuring icons and copy to explain the three key steps of a consultation. This will help potential customers understand how our 24/7 live veterinarian and video chat consultations operate and make them feel more confident in making a purchase.
                </div>
                <div className={'case-study-h2'}>Business Constraints</div>
                <div className={'case-study-paragraph'}>
                    Due to tight deadlines, limited engineering time and some shifting company priorities, we were only able to launch the new introduction screen and quiz question designs and had to launch a lower lift version of the recommendation screen. It was decided that the re-designed recommendation screen would be released in an upcoming sprint.
                </div>
                <div className={'case-study-h2'}>Results</div>
                <div className={'case-study-paragraph'}>
                    As a result of the improvements made to our quiz funnel, we saw a notable increase in conversion rates. Specifically, conversion rates throughout the funnel increased for a total of about 5% more customers making it to the recommendation screen. These improvements demonstrate the effectiveness of the changes we made and show that our efforts to enhance the design, functionality, and value proposition of our quiz funnel have been successful. We will continue to monitor and optimize our approach to further drive growth and success. We will reassess our metrics once the telehealth recommendation screen aspect of the redesign is launched.
                </div>
                <div className={'case-study-h2'}>In Hindsight</div>
                <div className={'case-study-paragraph'}>
                    Upon reflection, it would be beneficial to prioritize the pet health concern and pet type questions at the beginning of the quiz rather than later on in the process. This approach would enable us to address the specific needs and concerns of users from the outset, creating the perception of a more personalized and relevant experience while acknowledging the issue at hand. By addressing the user's specific issue at the start of the quiz, we can effectively engage them and encourage them to continue through the quiz, rather than abandoning the introduction screen.

                    I would also consider making the quiz continuation button on the introduction screen sticky on desktop after some resolution analysis. By analyzing the main desktop resolutions of our users, we can determine which screen sizes and resolutions are most common among our user base. This information can help us to design and layout the quiz introduction screen in a way that is optimized for these common screen sizes, ensuring that the quiz continuation button is prominently displayed and easy for users to access. Both considerations can help to improve the user experience and increase the completion rate for the quiz, thus impacting total member count and conversion rates.
                </div>
            </div>
            <Footer selected={'home'} />
        </div>
    )
}

export default TelehealthRedesign;