import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/case-study.css';
import '../css/sendsio.css';
import pic2 from '../images/cs/koala-vet-portal-redesign/2.png';
import pic3 from '../images/cs/koala-vet-portal-redesign/3.png';
import pic4 from '../images/cs/koala-vet-portal-redesign/4.png';
import pic5 from '../images/cs/koala-vet-portal-redesign/5.png';
import pic6 from '../images/cs/koala-vet-portal-redesign/6.png';
import pic7 from '../images/cs/koala-vet-portal-redesign/7.png';
import pic8 from '../images/cs/koala-vet-portal-redesign/8.png';
import pic9 from '../images/cs/koala-vet-portal-redesign/9.png';
import pic10 from '../images/cs/koala-vet-portal-redesign/10.png';
import pic11 from '../images/cs/koala-vet-portal-redesign/11.png';
import pic12 from '../images/cs/koala-vet-portal-redesign/12.png';
import pic13 from '../images/cs/koala-vet-portal-redesign/13.png';
import pic14 from '../images/cs/koala-vet-portal-redesign/14.png';
import pic15 from '../images/cs/koala-vet-portal-redesign/15.png';
import pic16 from '../images/cs/koala-vet-portal-redesign/16.png';
import pic17 from '../images/cs/koala-vet-portal-redesign/17.png';
import pic18 from '../images/cs/koala-vet-portal-redesign/18.png';
import pic19 from '../images/cs/koala-vet-portal-redesign/19.png';
import pic24 from '../images/cs/koala-vet-portal-redesign/24.png';
import pic25 from '../images/cs/koala-vet-portal-redesign/25.png';



import arrow from '../images/blue-arrow.png';

const KoalaVetPortalRedesign = () => {
    useEffect(() => {
        mixpanel.track('view case study - koala vet portal redesign');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'home'}></Header>
            <div className={'case-study-root'}>
                <div className={'case-study-title'}>KoalaVet Vet Portal Redesign</div>
                <div className={'case-study-h2'}>Background</div>
                <div className={'case-study-h3'}>Context</div>
                <div className={'case-study-paragraph'}>Koala Health is a pet pharmacy company that teams up with vets and pet parents to make pet medication as easy as possible. One way we do this is through our B2B vet portal, where vets can verify incoming prescriptions from clients and submit outgoing prescriptions for clients. This portal was a major part of our B2B growth strategy, so we wanted to understand what our vet partners needed and fix any user pain points within the current portal.</div>
                <div className={'case-study-ltr-arrow-container'}>
                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Problem
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            How might we create a simple, user-friendly and easy to use vet portal in order to increase overall engagement within KoalaVet via verifications and submissions?
                        </div>
                    </div>
                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Plan
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            Understand what vet partners need, fix any user pain points within the portal and transform the portal experience.
                        </div>
                    </div>
                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Goal
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            Boost engagement in KoalaVet through an increase in verifications and submissions by ensuring an excellent user experience for our vet partners.
                        </div>
                    </div>
                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Metrics
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            Engagement via verifications and submissions.
                            <br />
                            Current average number of V/S per vet partner: 0-1
                            <br />
                            Goal average number of V/S per vet partner: 10-12
                        </div>
                    </div>
                </div>
                <br/>
                <div className={'case-study-h2'}>What is the current user experience?</div>
                <div>
                    <video className={'order-history-video'} controls >
                        <source src="./videos/koala-vet-portal-redesign-1.mp4" type="video/mp4"/>
                    </video>
                </div>

                <br/>
                <div className={'case-study-h2'}>Getting Started</div>
                <div className={'case-study-h3'}>User Research</div>
                <div className={'case-study-paragraph'}>I started by exploring the vet portal to identify any confusing or unintuitive elements that our vet partners might face. How do I think they may be perceiving the portal? Do these flows match their workflow and how they would move through the process? This helped me draft a comprehensive usability test plan, conduct user interviews and discover the overarching pain points</div>
                <div className={'case-study-h3'}>What were the overarching pain points?</div>
                <ul className={'case-study-paragraph'}>
                    <li>Poor perception of vet portal look/feel</li>
                    <br/>
                    <li>Verifying prescriptions is confusing for vets so they are sometimes unable to verify</li>
                    <br/>
                    <li>Submitting prescriptions is time-consuming, confusing and sometimes could not be completed</li>
                    <br/>
                    <li>Vets confused about where to view client and pet details</li>
                    <br/>
                    <li>Users felt they were unable to add clients and that adding pets was tedious</li>
                    <br/>
                    <li>Vet frustrated with lack of order history/status since they are used to being able to view that history and also see what the status of the order</li>
                    <br/>
                    <li>Vet frustrated with lack of medication history for pets and inability to add medication history when adding a pet</li>
                </ul>
                <div className={'case-study-h3'}>Competitive Research</div>
                <div className={'case-study-paragraph'}>With the knowledge of the big pain points in mind,I took a close look at other industry leaders like Vetsource and Covetrus. I wanted to see what they were doing well and where they were falling short in terms of user experience. This research helped me understand industry best practices and gave me ideas for our upcoming design decisions. By exploring their approaches and product styles, I gained valuable insights that would inform design approach and decisions and allow us to enhance our portal and provide a better experience for our vet partners.</div>
                <div className={'case-study-h3'}>User Flows</div>
                <div className={'case-study-paragraph'}>To ensure a comprehensive user experience, I mapped out the various main user flows within the portal. Each step of the user journey was documented to guarantee that users could seamlessly navigate through the portal. Some of these include the prescription verification order, prescription submission flow, adding client/pets flow, and editing client/pets flow. (Pictured below is the “add new pet to client profile” flow)</div>
                <div className={'centered-pic-container'}>
                    <img className={'centered-pic'} src={pic2} />
                </div>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h3'}>Creating a design system</div>
                <div className={'case-study-paragraph'}>In this redesign, we were working to improve both functionality/accessibility as well as aesthetic. To achieve a modern visual design, I developed a  comprehensive design system, comprising icons, colors, buttons, and components. This system ensured consistency and streamlined the UI creation process helping us achieve a visually appealing and accessible portal.</div>
                <div className={'centered-pic-container'}>
                    <img className={'centered-pic'} src={pic3} />
                </div>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h3'}>Pain points and Solutions</div>
                <div className={'case-study-pain-point'}>
                    <div className={'case-study-pain-point-1'}>OVERARCHING PAIN POINT:</div> <div className={'case-study-pain-point-2'}>Poor perception of vet portal look/feel</div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 1:</div>
                        <div className={'case-study-pain-point-desc'}>The portal's appearance was perceived as outdated due to the color scheme and didn't match the feel of other vet portals</div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 1:</div>
                        <div className={'case-study-pain-point-desc'}>Transform portal's appearance to reflect a more modern, health related aesthetic appearance was perceived as outdated due to the color scheme and didn't match the feel of other vet portals</div>
                    </div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 2:</div>
                        <div className={'case-study-pain-point-desc'}>Portal lacked clear navigation, making it hard for users to move around easily</div>
                        <img className={'cs-pic'} src={pic4} />
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 2:</div>
                        <div className={'case-study-pain-point-desc'}>Provide vets with navigation so they may easily navigate to different parts of the portal to accomplish different tasks</div>
                        <img className={'cs-pic'} src={pic5} />
                    </div>
                </div>

                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-pain-point'}>
                    <div className={'case-study-pain-point-1'}>OVERARCHING PAIN POINT:</div> <div className={'case-study-pain-point-2'}>Verifying prescriptions is confusing for vets</div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 1:</div>
                        <div className={'case-study-pain-point-desc'}>Veterinarians faced challenges in understanding the functionality of the "assigned to" dropdown.</div>
                        <img className={'cs-pic'} src={pic6} />
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 1:</div>
                        <div className={'case-study-pain-point-desc'}>Ensure vets understand that they are meant to select their name letting us know who they are before starting a prescription verification</div>
                        <img className={'cs-pic'} src={pic7} />
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 2:</div>
                        <div className={'case-study-pain-point-desc'}>In some cases, approving or denying actions proved to be unclear for vets</div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 2:</div>
                        <div className={'case-study-pain-point-desc'}>Ensure the action of choosing between approving and rejecting a specific product item in a prescription order is very clear </div>
                    </div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 3:</div>
                        <div className={'case-study-pain-point-desc'}>The portal's layout included numerous inputs, with some appearing redundant, leading to user frustration. </div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 3:</div>
                        <div className={'case-study-pain-point-desc'}>Limit the inputs to only which are necessary</div>
                    </div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 4:</div>
                        <div className={'case-study-pain-point-desc'}>Vets were unsure about the content required under "Additional notes," indicating a lack of clarity in this section</div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 4:</div>
                        <div className={'case-study-pain-point-desc'}>Provide clarification to vets so they understand they can provide optional additional notes to the pharmacist fulfilling the order</div>
                    </div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 5:</div>
                        <div className={'case-study-pain-point-desc'}>The term "Complete order" posed confusion for vets, given that clients had already finished the order process</div>
                        <img className={'cs-pic'} src={pic8} />
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 5:</div>
                        <div className={'case-study-pain-point-desc'}>Ensure CTA’s are appropriately worded for action clarity</div>
                        <img className={'cs-pic'} src={pic9} />
                    </div>
                </div>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-pain-point'}>
                    <div className={'case-study-pain-point-1'}>OVERARCHING PAIN POINT:</div> <div className={'case-study-pain-point-2'}>Submitting prescriptions is time-consuming, confusing and some could not complete</div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 1:</div>
                        <div className={'case-study-pain-point-desc'}>Some vets did not understand what they were expected to input on "Days supplied" form field</div>
                        <img className={'cs-pic'} src={pic10} />
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 1:</div>
                        <div className={'case-study-pain-point-desc'}>Eliminate Days of Supply field (meant to convey shipping frequency) and automate it to select the lowest shipping frequency dependent on the product.</div>
                        <img className={'cs-pic'} src={pic11} />
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 2:</div>
                        <div className={'case-study-pain-point-desc'}>Vets mentioned they often need to prescribe more than one medication for one pet and doing so requires a lot of clicking back and forth</div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 2:</div>
                        <div className={'case-study-pain-point-desc'}>Make it easy and efficient for vets to prescribe more than one prescription for more than one pet so they can accomplish prescription submission quickly</div>
                    </div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 3:</div>
                        <div className={'case-study-pain-point-desc'}>Most vets believed they had already submitted prescriptions after adding them without realizing the additional requirement of submitting them for the prescriptions to be sent to Koala so some could not submit</div>
                        <img className={'cs-pic'} src={pic12} />
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 3:</div>
                        <div className={'case-study-pain-point-desc'}>Transform UI and use proper verbiage to ensure vets can easily fully submit a prescription</div>
                        <img className={'cs-pic'} src={pic13} />
                    </div>
                </div>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-pain-point'}>
                    <div className={'case-study-pain-point-1'}>OVERARCHING PAIN POINT:</div>
                    <div className={'case-study-pain-point-2'}> Vets confused about where to view client and pet details</div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 1:</div>
                        <div className={'case-study-pain-point-desc'}>Most vets were unsure where to navigate to see information about weight, breed of a specific client’s pet</div>
                        <img className={'cs-pic'} src={pic14} />
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 1:</div>
                        <div className={'case-study-pain-point-desc'}>Provide client list with client rows that are clickable so vets can click a row and see both pet and client information</div>
                        <img className={'cs-pic'} src={pic15} />
                    </div>
                </div>

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-pain-point'}>
                    <div className={'case-study-pain-point-1'}>OVERARCHING PAIN POINT: </div>
                    <div className={'case-study-pain-point-2'}>Users felt they were unable to add clients and that adding pets was tedious</div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 1:</div>
                        <div className={'case-study-pain-point-desc'}>Most vets were under the perception they had not fully added a client because the portal indicates the client is registered but we need authorization in order to proceed</div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 1:</div>
                        <div className={'case-study-pain-point-desc'}>Create an add client flow that is not dependent on whether the user is currently using Koala as a pharmacy (authorized) and allows vets to finish client creation with certainty</div>
                    </div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 2:</div>
                        <div className={'case-study-pain-point-desc'}>They felt adding a client holistically covered adding pets since pets are the end patient and were surprised that was a separate process they had to navigate to. They were then unsure how to add pets from the home screen</div>
                        <img className={'cs-pic'} src={pic16} />
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 2:</div>
                        <div className={'case-study-pain-point-desc'}>Create a simple add client flow that allows vets to create an entire profile including both client and pet information</div>
                        <img className={'cs-pic'} src={pic17} />
                        <img className={'cs-pic'} src={pic18} />
                        <img className={'cs-pic'} src={pic19} />
                    </div>
                </div>

                <br/>
                <br/>
                <br/>
                <div className={'case-study-h2'}>Final Designs</div>
                <div className={'case-study-h3'}>Verifying a prescription</div>
                <div className={'video-side-by-side'}>
                    <video className={'final-left'} width="100%" controls >
                        <source src="./videos/koala-vet-portal-redesign-20.mp4" type="video/mp4"/>
                    </video>
                    <video width="100%" className={'final-right'} controls >
                        <source src="./videos/koala-vet-portal-redesign-21.mp4" type="video/mp4"/>
                    </video>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h3'}>Submitting a prescription</div>
                <div>
                    <video className={'order-history-video'} controls >
                        <source src="./videos/koala-vet-portal-redesign-22.mp4" type="video/mp4"/>
                    </video>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h3'}>Viewing clients and adding clients/pets</div>
                <div>
                    <video className={'order-history-video'} controls >
                        <source src="./videos/koala-vet-portal-redesign-23.mp4" type="video/mp4"/>
                    </video>
                </div>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h2'}>Usability Testing</div>
                <div className={'case-study-h3'}>What we did well</div>
                <ul className={'case-study-paragraph'}>
                    <li>Elevated the look and feel of the vet portal with vets describing the portal as “clean”, “modern”, “easy to use”</li>
                    <li>Provided proper navigation that vet users described as “organized” and “simple”</li>
                    <li>Vets felt the prescription order verification flow was quick, easy to understand and were able to verify a prescription order without confusion</li>
                    <li>Vets felt the prescription submission flow was time-efficient, clear,  and concise and they were able to navigate and submit prescription without issue</li>
                    <li>Vets were able to easily navigate to a client profile to view client and pet details</li>
                    <li>Vets were able to add clients easily and it was clear to them they had added the client</li>
                    <li>Vets were able to easily add client details and pet information in the same flow describing the flow as more efficient</li>
                </ul>
                <div className={'case-study-h3'}>What we need to improve</div>

                <ul className={'case-study-paragraph'}>
                    <li>"Phone number is unknown" and "Email address is unknown" prompts are unnecessary because almost all the time, the vet has both pieces of information</li>
                </ul>
                <div className={'case-study-h2'}>Improvements</div>
                <div className={'case-study-paragraph'}>
                    Some of our vet partners conveyed that in the vast majority of cases, they had access to both phone numbers and email addresses for clients. With this knowledge in mind, we took proactive steps to optimize the user interface. As a result, we eliminated the "Phone number is unknown" and "Email address is unknown" prompts and facilitated seamless progression ability once vets had populated at least one of the fields. This transformation not only streamlines the user experience but also eliminates unnecessary clutter from the UI, ensuring a more efficient interaction.
                </div>
                <div className={'pic-side-by-side-no-side-margin'}>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic24} />
                    </div>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic25} />
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h2'}>Business Constraints/Challenges</div>
                <div className={'case-study-paragraph'}>
                    Due to limitations in time and resources, coupled with the intricate logic of order history and order statuses with the pharmacy fulfillment PetOS workflow steps as well as future PIMS integration plans, a strategic decision was made to prioritize solving access to order history/status and Rx list history pain points in the future. While the order history feature will not launch with the MVP, designs had to be flexible enough to scale once we added it in, requiring consideration for a feature we knew little about at the time. It was challenging but important to consider how certain components can be used in multiple ways, and how layouts can be altered to fit a new layer of information.
                </div>

                <div className={'case-study-h2'}>Results</div>
                <div className={'case-study-paragraph'}>
                    Initially, the MVP portal struggled with low activity, <b>averaging just 0-1 verifications and submissions per vet partner each day,</b> while our aspiration was to elevate this metric to 10-15 verifications and submissions per partner daily. Although we did not fully reach this goal, the redesign yielded commendable results. We observed an improvement in portal activity, with an <b>average of 5 verifications and 3 submissions per day per partner.</b> This positive shift underscores the positive impact of the redesign on user engagement and interaction.
                </div>
                <div className={'case-study-h2'}>In Hindsight</div>
                <div className={'case-study-paragraph'}>
                    In hindsight, I would have explored a modal UI for the prescription verifications similar to how the add prescription flow and add client/pets flow look just to keep the UI consistent across the board. I did verification flow first and only started considering modals after the fact. I have plans to re-visit the UI and evaluate whether a modal design may be a good option for the verification flow. In the future, I’d like to iterate upon this design when we have new learnings and more data to inform our design decisions.
                </div>
            </div>
            <Footer selected={'home'} />
        </div>
    )
}

export default KoalaVetPortalRedesign;