import React, {useRef, useState} from 'react';
import button from '../images/see-my-work-btn.png';
import buttonHover from '../images/see-my-work-btn-hover.png';
import meteor1 from '../images/meteor-1.png';
import meteor2 from '../images/meteor-2.png';
import meteor3 from '../images/meteor-3.png';
import meteor4 from '../images/meteor-4.png';
import bgImage from '../images/above-the-fold-bg.png';
import '../css/above-the-fold.css';
import mixpanel from "mixpanel-browser";
import Header from "../components/Header";
import useOnScreen from "../hooks/useOnScreen";

const AboveTheFold = () => {
    const ref1 = useRef(null);
    const isInView1 = useOnScreen(ref1);

    const [isButtonHovered, setButtonHovered] = useState(false);  // State for button hover

    const handleButtonClick = () => {
        mixpanel.track('Click see my work');
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth' // smooth scrolling effect
        });
    };

    return (
        <div
            style={{ backgroundImage: `url(${bgImage})` }}
            className="min-h-screen w-full flex relative flex-col items-center justify-center bg-custom-gray bg-cover bg-no-repeat overflow-hidden z-30"
        >
            <div className={'w-full top-0 absolute z-20 main-container'}>
                <Header selected={'home'} white></Header>
            </div>
            {/* Meteors */}
            <img src={meteor1} className="meteor meteor-1 z-10" alt="Meteor" />
            <img src={meteor2} className="meteor meteor-2 z-10" alt="Meteor" />
            <img src={meteor3} className="meteor meteor-3 z-10" alt="Meteor" />
            <img src={meteor4} className="meteor meteor-4 z-10" alt="Meteor" />
            <img src={meteor1} className="meteor meteor-5 z-10" alt="Meteor" />
            <img src={meteor2} className="meteor meteor-6 z-10" alt="Meteor" />
            <img src={meteor3} className="meteor meteor-7 z-10" alt="Meteor" />
            <img src={meteor4} className="meteor meteor-8 z-10" alt="Meteor" />
            <img src={meteor1} className="meteor meteor-9 z-10" alt="Meteor" />
            <img src={meteor2} className="meteor meteor-10 z-10" alt="Meteor" />
            <img src={meteor3} className="meteor meteor-11 z-10" alt="Meteor" />
            <img src={meteor4} className="meteor meteor-12 z-10" alt="Meteor" />
            <img src={meteor1} className="meteor meteor-13 z-10" alt="Meteor" />
            <img src={meteor2} className="meteor meteor-14 z-10" alt="Meteor" />

            <div className="mt-48 font-open-sans  font-medium leading-140 text-white z-20 mx-10 md:mx-20 fade-in-fast" ref={ref1}>
                <div className={"flex justify-center text-2xl md:text-6xl"}>
                    <div className={"inline-block"}>
                        <p className="text-center mb-2 animated-text-1">Hello, I'm <div className="inline text-custom-blue font-bold">Michelle B. Malik.</div></p>
                    </div>
                </div>
                <div className="m-auto max-w-[810px] text-center text-lg md:text-3xl mt-4">I'm a Product Designer passionate about clean, functional and innovative design for desktop and web/mobile apps</div>
                <div className="flex items-center justify-center"> {/* This parent div fills the screen and centers its children */}
                    <img
                        width={485}
                        height={305}
                        src={isButtonHovered ? buttonHover : button}  // Conditional rendering based on hover state
                        className="cursor-pointer"
                        alt="Button"
                        onMouseEnter={() => setButtonHovered(true)}  // Set hover state to true when mouse enters
                        onMouseLeave={() => setButtonHovered(false)}  // Set hover state to false when mouse leaves
                        onClick={handleButtonClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default AboveTheFold;
