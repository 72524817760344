import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/case-study.css';
import '../css/sendsio.css';
import pic1 from '../images/cs/order-history/1.png';
import pic2 from '../images/cs/order-history/2.png';
import pic3 from '../images/cs/order-history/3.png';
import pic4a from '../images/cs/order-history/4a.png';
import pic4b from '../images/cs/order-history/4b.png';
import pic4c from '../images/cs/order-history/4c.png';
import pic5 from '../images/cs/order-history/5.png';
import pic6 from '../images/cs/order-history/6.png';
import pic7 from '../images/cs/order-history/7.png';



import arrow from '../images/blue-arrow.png';

const OrderHistory = () => {
    useEffect(() => {
        mixpanel.track('view case study - order history');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'home'}></Header>
            <div className={'case-study-root'}>
                <div className={'case-study-title'}>KoalaVet: Order History/Status Feature</div>
                <div className={'case-study-h2'}>Background</div>
                <div className={'case-study-h3'}>Context</div>
                <div className={'case-study-paragraph'}>To enhance the veterinary experience, specifically in Koala's vet portal "KoalaVet," I conducted some user research. The main goal was to uncover issues, point of confusion, and identify challenging elements for vets. Among the insights, I found that vets were uncertain about verifying and submitting information due to their inability to view the history of things they have verified/submitted and lack of access to the order statuses of those same orders. This was information they were used to having in other vet portals. After this, the B2B team spec’d an "Order History and Status" feature to boost vets' confidence in verifying and submitting prescriptions</div>
                <div className={'case-study-ltr-arrow-container'}>
                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Problem
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            Vets were apprehensive about verifying/submitting prescription orders because they did not have access to history and status information which was negatively affecting engagement. How might we provide transparency into order histories and detailed statuses in order to boost engagement?
                        </div>
                    </div>
                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Plan
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            Boost engagement by designing a feature that allows vets to view order histories of prescriptions they have verified/submitted and detailed statuses so they are always informed
                        </div>
                    </div>

                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Metrics
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            Engagement via verifications and submissions.
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className={'case-study-h2'}>Getting Started</div>
                <div className={'case-study-h3'}>User Research</div>
                <div className={'case-study-paragraph'}>In a previous research study, we discovered a pain point around lack of order history and order statuses but we needed to understand more about what information vets needed access to in order to feel confident verifying and submitting prescriptions within the portal. We were able to discuss this pain point with some of our vet partners and discover what they needed by asking questions such as, </div>
                <ul className={'case-study-paragraph'}>
                    <li>
                        “What type of information would you need available to you and why for an order you have verified within the portal?”
                    </li>
                    <br/>
                    <li>
                        “Can you walk me through a scenario in which your client had not received a prescription order. Describe why you believe they had not received it and outline the steps you took to investigate and understand the situation.”
                    </li>


                </ul>
                <div className={'case-study-h3'}>What were the overarching pain points?</div>
                <ul className={'case-study-paragraph'}>
                    <li>Vets don’t know what is going on with the orders they have verified and submitted: around where the order is, if it’s being filled, processed, if there is an issue they need to solve or an issue the customer needs to solve.</li>
                    <br/>
                    <li>Some vets don’t feel that they we as their pharmacy provider are being transparent with them</li>
                    <br/>
                    <li>It is frustrating to remember a prescription order from weeks ago but not remember the details of it: what prescriptions were in the order, for which pet, when it was ordered, if there is a hold up or issue and if the customer ever got it</li>
                    <br/>
                    <li>They are unsure if they need to take any further actions after verifying or submitting a prescription order because we don’t give them any updates in the portal after they do so</li>
                    <br/>
                    <li>They get notifications that prescription orders have been canceled and they have no understanding of why</li>
                    <br/>
                    <li>They have to search through email with a client name or pet name to find an order and call us every time they need to check on a client’s order they have submitted or verified</li>
                    <br/>
                </ul>
                <div className={'case-study-h3'}>Competitive Research</div>
                <div className={'case-study-paragraph'}>I took a close look at other industry leaders like Vetsource and Covetrus, wanting to see what they were doing well and where they were falling short in terms of user experience. This research helped me understand vet industry best practices and gave me ideas for our upcoming design decisions. By exploring their approaches to order history, I gained valuable insights that would inform design approach and decisions and allow us to provide a simple, functional experience for our vet partners.</div>
                <div className={'case-study-h3'}>User Flows</div>
                <div className={'case-study-paragraph'}>The main flows were relatively simple. The vet would either view the order history from the Order History Dashboard or would view the order history via searching for a client within the client list</div>
                <img className={'centered-pic'} src={pic1} />
                <br/>
                <br/>
                <br/>
                <br/>
                <img className={'centered-pic'} src={pic2} />
                <br/>
                <br/>

                <div className={'case-study-paragraph'}>
                    The intricacy of this feature was not in the flows themselves but more so in the statuses. The different status buckets:
                    <br/>
                    <br/>
                    <ul>
                        <li>Ordered</li>
                        <li>Submitted</li>
                        <li>Approved/Rejected</li>
                        <li>Processing</li>
                        <li>Shipped</li>
                        <li>Delivered</li>
                    </ul>
                    <br/>
                    cover the information our vet partners need access to and each of these buckets correspond with particular PetOs workflow steps (an internal tool used to fulfill orders on the pharmacy side) such as <b>10a. Escalation: Payment Failure - Customer Contact 1 : Client payment is invalid. Koala is contacting the client for alternate payment.</b>
                    <br/>
                    <br/>
                    To ensure we were accounting for both common use cases and edge use case, I mapped out all of the possibilities, matching up each PetOS step with a status bucket and formulating vet-friendly verbiage that vets could see to be informed on the status of the order. Pictured below:
                </div>
                <img className={'centered-pic'} src={pic3} />
                <div className={'case-study-h3'}>Design Exploration</div>
                <div className={'case-study-paragraph'}>I delved into some lofi’s exploring different layouts and UI to find a functional solution. I obtained feedback from product and engineering along the way, keeping all stakeholders in the loop on my ideation and direction</div>

                <div className={'x3-pic-side-by-side'}>
                    <div className={'centered-pic-3x'}>
                        <img className={'centered-pic'} src={pic4a} />
                    </div>
                    <div className={'centered-pic-3x'}>
                        <img className={'centered-pic'} src={pic4b} />
                    </div>
                    <div className={'centered-pic-3x'}>
                        <img className={'centered-pic'} src={pic4c} />
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h3'}>Pain points and Solutions</div>
                <br/>
                <br/>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 1:</div>
                        <div className={'case-study-pain-point-desc'}>
                            Vets don’t know what is going on with the orders they have verified and submitted: around where the order is, if it’s being filled, processed, if there is an issue they need to solve or an issue the customer needs to solve.
                            <br/>
                            <br/>
                            Some vets don’t feel that they we as their pharmacy provider are being transparent with them</div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 1:</div>
                        <div className={'case-study-pain-point-desc'}>Provide vets with an order history dashboard where they can see every order with the order #, client name, pets with prescriptions in the order, prescription products in the order and the status of the order</div>
                    </div>
                </div>
                <img className={'centered-pic'} src={pic5} />
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 2:</div>
                        <div className={'case-study-pain-point-desc'}>It is frustrating to remember a prescription order from weeks ago but not remember the details of it: what prescriptions were in the order, for which pet, when it was ordered, if there is a hold up or issue and if the customer ever got it.</div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 2:</div>
                        <div className={'case-study-pain-point-desc'}>Order Detail view with information on order #, client name, pets with prescriptions in the order, prescription products in the order along with prescription details for each product</div>
                    </div>
                </div>
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 3:</div>
                        <div className={'case-study-pain-point-desc'}>They are unsure if they need to take any further actions after verifying or submitting a prescription order because we don’t give them any updates in the portal after they do so.
                            <br />
                            <br />
                            They get notifications that prescription orders have been canceled and they have no understanding of why.
                        </div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 3:</div>
                        <div className={'case-study-pain-point-desc'}>Detailed status for each order in Order detail view with detailed messaging to vets allowing them to understand what the actual status of the order is and if any action is required of them covering all potential order status paths</div>
                    </div>
                </div>
                <img className={'centered-pic'} src={pic6} />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className={'case-study-pain-point-arrows-container'}>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Pain Point 4:</div>
                        <div className={'case-study-pain-point-desc'}>They have to search through email with a client name or pet name to find an order #  and call us everytime they need to check on an order they recall having submitted or verified</div>
                    </div>
                    <div className={'case-study-pain-point-arrow'}>
                        <img className={'case-study-pain-point-arrow-img'} src={arrow}/>
                    </div>
                    <div className={'case-study-pain-point-left-right'}>
                        <div className={'case-study-pain-point-number'}>Solution 4:</div>
                        <div className={'case-study-pain-point-desc'}>Ability to view order history on the client level to quickly locate order they are looking to inquire about with ease</div>
                    </div>
                </div>
                <img className={'centered-pic'} src={pic7} />
                <br/>
                <br/>
                <br/>
                <br/>

                <div className={'case-study-h2'}>Final Designs (Desktop and Mobile web)</div>
                <div>
                    <video className={'order-history-video'} controls >
                        <source src="./videos/order-history-final.mp4" type="video/mp4"/>
                    </video>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h2'}>Usability Testing</div>
                <div className={'case-study-h3'}>What we did well</div>
                <ul className={'case-study-paragraph'}>
                    <li>All users were able to locate specific orders and find out what the specific status of the order was</li>
                    <br/>
                    <li>All users understood when they needed to take action vs. when they did not after verifying or submitting a prescription order</li>
                    <br/>
                    <li>They don’t feel that we as their pharmacy provider are being transparent with them</li>
                    <br/>
                    <li>All users were able to navigate to the order details and access detailed informations including but not limited to products in the order and the details of the prescription</li>
                    <br/>
                    <li>All users were able to understand when an order was canceled and the reason it was canceled</li>
                    <br/>
                    <li>Most users were able to navigate and find specific orders they have verified or submitted for a particular client or pet</li>
                </ul>
                <div className={'case-study-h3'}>What we need to improve</div>

                <ul className={'case-study-paragraph'}>
                    <li>A couple of users were initially confused on how to access order details, history and status for a specific client and expected there to be a search option on the order history dashboard or home page rather than having to navigate to a client profile and view order history that way. However, all subjects navigated to the client profile after initial confusion.</li>
                </ul>
                <div className={'case-study-h2'}>Improvements</div>
                <div className={'case-study-paragraph'}>
                    On initial ideation, we had considered a search bar on the order history dashboard to address vets inability to easily locate a client’s order they need to inquire about or check up on. While we felt that was the best user experience, we wanted to implement a global search on the Home page in the future. The decision was made to give vets the ability to view order history on the client level as a placeholder before implementing global search. Due to some technical constraints, the tech team felt it was best to save the search work for the future and move forward with the aforementioned alternate solution.
                </div>
                <div className={'case-study-h2'}>Results</div>
                <div className={'case-study-paragraph'}>
                    We expect to see an increase in the verifications and submissions within the portal after the upcoming launch of this feature, however, we have no concrete data at this time.
                </div>
                <div className={'case-study-h2'}>In Hindsight</div>
                <div className={'case-study-paragraph'}>
                    I encountered challenges during the user flow stage of the design process due to a lack of clear comprehension of the petOS logic. In hindsight, I would have had more extensive discussions with the pharmacy operations team during the product spec phase. This would have helped me understand the intricacies of the petOS steps comprehensively, their interconnections, the potential for orders to transition forward and backward between steps, and how these petOS steps ultimately shape the order status. This proactive approach would have facilitated a smoother and more efficient design process.
                </div>
            </div>
            <Footer selected={'home'} />
        </div>
    )
}

export default OrderHistory;