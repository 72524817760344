import '../css/footer.css'
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const Footer = ({selected}) => {
    const navigate = useNavigate();
    const gotoResume = () => {
        mixpanel.track('view resume');
        window.open('/Michelle Malik - Resume.pdf', '_blank');
    };
    const scrollAndNavigate = (url) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        navigate(url);
    };

    return (
        <div className={'footer'}>
            <div className={`footer-item ${selected === 'home' ? 'selected':''}`} onClick={scrollAndNavigate.bind(this, '/')}>WORK</div>
            <div className={'footer-slash'}>/</div>
            <div className={`footer-item ${selected === 'about' ? 'selected':''}`} onClick={scrollAndNavigate.bind(this, '/about')}>ABOUT</div>
            <div className={'footer-slash'}>/</div>
            <div className={`footer-item ${selected === '' ? 'selected':''}`} onClick={gotoResume}>RESUME</div>
            <div className={'footer-slash'}>/</div>
            <div className={`footer-item ${selected === 'contact' ? 'selected':''}`} onClick={scrollAndNavigate.bind(this, '/contact')}>CONTACT</div>
        </div>
    );
}

export default Footer;