import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/case-study.css';
import '../css/sendsio.css';
import pic1 from '../images/cs/self-sign-up/1.png';
import pic2 from '../images/cs/self-sign-up/2.png';
import arrow from '../images/blue-arrow.png';

const VetSelfSignUp = () => {
    useEffect(() => {
        mixpanel.track('view case study - order history');
    }, []);

    return (
        <div className={"main-container"}>
            <Header selected={'home'}></Header>
            <div className={'case-study-root'}>
                <div className={'case-study-title'}>KoalaVet: Vet Self Sign Up Flow</div>
                <div className={'case-study-h2'}>Background</div>
                <div className={'case-study-h3'}>Context</div>
                <div className={'case-study-paragraph'}>Koala Health is a pet pharmacy company that teams up with vets and pet parents to make pet medication as easy as possible. One way we do this is through our B2B vet portal which streamlines prescription processing, allowing vets to verify and submit prescriptions effortlessly. Currently, our sales team schedules demos through cold calls, a time-consuming process. To enhance partner acquisition, we needed to create a way for vets to self-sign-up for vet portal/partnership demos, shifting towards a more efficient and asynchronous approach, complementing our sales efforts.</div>
                <div className={'case-study-ltr-arrow-container'}>
                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Problem
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            How might we facilitate a seamless and efficient process for veterinarians to sign up for demos with our sales team, reducing the dependency on cold calls to secure demos?
                        </div>
                    </div>
                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Plan
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            Increase the number of demos scheduled to ultimately increase the number of new b2b vet partners we acquire.
                        </div>
                    </div>

                    <div className={'case-study-ltr-arrow-row'}>
                        <div className={'case-study-ltr-arrow-left'}>
                            Metrics
                        </div>
                        <div className={'case-study-ltr-arrow'}>
                            <img src={arrow} />
                        </div>
                        <div className={'case-study-ltr-arrow-right'}>
                            # of Demo sign ups
                            <br/>
                            # of b2b vet partners
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className={'case-study-h2'}>Getting Started</div>
                <div className={'case-study-h3'}>User Research</div>
                <div className={'case-study-paragraph'}>I started by having some discussions with vets who had been practicing for 1 year or above. I aimed to uncover key partnership preferences, pharmacy partner differentiating factors, potential motivations for considering Koala as a pharmacy partner and the do’s and don'ts for an ideal schedule demo flow. Some of those user interview questions are provided below:</div>
                <ul className={'case-study-paragraph'}>
                    <li>
                        In a vet/pet pharmacy partnership, what aspects matter most to you?
                    </li>
                    <br/>
                    <li>
                        What specific features or services would encourage you to consider Koala Health as a pharmacy partner?
                    </li>
                    <br/>
                    <li>
                        Do you have clients that have a difficult time with medication adherence? Tell me more.
                    </li>
                    <br/>
                    <li>
                        When evaluating potential pharmacy partnerships, what information or assurances would make you more inclined to schedule a demo with us?
                    </li>
                    <br/>
                    <li>
                        Are there any issues you've encountered when requesting more information/ a demo that have prevented you from doing so?
                    </li>
                    <br/>
                    <li>
                        When considering requesting a demo from a potential pharmacy partner, what factors influence your decision to proceed?
                    </li>
                    <br/>
                    <li>
                        What information would you prefer to have readily available before initiating the "request a demo" process?
                    </li>
                    <br/>
                    <li>
                        Do you have any concerns or hesitations when it comes to initiating a demo request? What would alleviate those concerns?
                    </li>
                    <br/>
                </ul>
                <div className={'case-study-h3'}>Findings</div>
                <ul className={'case-study-paragraph'}>
                    <li>Vets have clients that have issues with medication adherence and would be interested in special pouching to combat that</li>
                    <br/>
                    <li>Vets want to know what is special about partnering with Koala</li>
                    <br/>
                    <li>Vets care about the benefits to them but are also concerned about what we can provide for pets and pet parents</li>
                    <br/>
                    <li>Scheduling demos for vet partner programs and Pet information management systems is usually impersonal</li>
                    <br/>
                    <li>Filling in form field on a form is generally annoying</li>
                    <br/>
                    <li>Vets care about fair compensation and want to know what compensation looks like</li>
                    <br/>
                    <li>Vets schedule demos if they feel like the company has demonstrated ways they can benefit them</li>
                    <br/>
                    <li>Testimonials/reviews from clients (pet parents) and current vet partners are a big indicator of value (good or bad) to vets</li>
                    <br/>
                </ul>
                <div className={'case-study-h3'}>Competitive Research</div>
                <div className={'case-study-paragraph'}>I researched various competitors within our industry, such as Vetsource, Covetrus, and Chewy. Additionally, I explored companies outside our industry to see different approaches to landing pages and requesting demo processes. Through this, I identified some trends. For instance, I appreciated the inclusion of value propositions that highlighted the value of the product. However, I disliked certain patterns, like the utilization of lengthy forms with as many as 8 input fields, which can feel tedious and create a sense of prolonged interaction.</div>

                <div className={'pic-side-by-side-no-side-margin'}>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic1} />
                    </div>
                    <div className={'cs-pic-container-sbs'}>
                        <img className={'cs-pic'} src={pic2} />
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={'case-study-h2'}>Final Designs (Desktop and Mobile web)</div>
                <div className={'case-study-paragraph'}>
                    Maintaining company branding guidelines and aesthetic, I created a vet self sign up landing page for desktop/mobile web that provided vets with:
                    <ul>
                        <li>partnership value propositions</li>
                        <br/>
                        <li>Koala’s unique approach to pet medication: pouching</li>
                        <br/>
                        <li>what we can provide for their clients</li>
                        <br/>
                        <li>a depiction of our portal showcasing its simple and modern interface</li>
                        <br/>
                        <li>Koala’s unique approach to supporting vets themselves</li>
                        <br/>
                    </ul>
                </div>
                <div className={'video-side-by-side'}>
                    <video className={'final-left'} width="100%" controls >
                        <source src="./videos/self-sign-up-final-left.mp4" type="video/mp4"/>
                    </video>
                    <video width="100%" className={'final-right'} controls >
                        <source src="./videos/self-sign-up-final-right.mp4" type="video/mp4"/>
                    </video>
                </div>
                <br/>
                <div className={'case-study-h2'}>Business Constraints</div>
                <div className={'case-study-paragraph'}>
                    We were unable to provide specific information around Koala’s compensation model, something vets mentioned being important to them, because at this time Koala is taking a tailored approach to the compensation aspect of each vet partnership, working with the vet to figure out what compensation numbers are most suitable for them. This model will change in the future but due to this current approach, stakeholders did not want to give any specific numbers.
                </div>
                <div className={'case-study-h2'}>Results</div>
                <div className={'case-study-paragraph'}>
                    Before this project we were gaining an <b>average of 2 partners a month</b> from our small sales team cold calling, scheduling demos and converting those demos into partnerships. In the following 2 months after implementation we saw an <b>average of 19 demos scheduled per month with an average of 5 new vet partners a month</b> just from the landing page/request demo flow itself.
                </div>
            </div>
            <Footer selected={'home'} />
        </div>
    )
}

export default VetSelfSignUp;