import {useEffect, useRef} from 'react';
import mixpanel from 'mixpanel-browser';
import '../css/home.css';
import PortfolioItem from "../components/PortfolioItem";

import pImage1 from '../images/item-1.png';
import pImage1Hover from '../images/item-1-hover.png';
import pImage2 from '../images/item-2.png';
import pImage2Hover from '../images/item-2-hover.png';
import pImage3 from '../images/item-3.png';
import pImage3Hover from '../images/item-3-hover.png';
import pImage4 from '../images/item-4.png';
import pImage4Hover from '../images/item-4-hover.png';
import pImage5 from '../images/item-5.png';
import pImage5Hover from '../images/item-5-hover.png';
import pImage6 from '../images/item-6.png';
import pImage6Hover from '../images/item-6-hover.png';
import pImage7 from '../images/item-7.png';
import pImage7Hover from '../images/item-7-hover.png';
import pImage8 from '../images/item-8.png';
import pImage8Hover from '../images/item-8-hover.png';
import pImage1Mobile from '../images/item-1-mobile.png';
import pImage2Mobile from '../images/item-2-mobile.png';
import pImage3Mobile from '../images/item-3-mobile.png';
import pImage4Mobile from '../images/item-4-mobile.png';
import pImage5Mobile from '../images/item-5-mobile.png';
import pImage6Mobile from '../images/item-6-mobile.png';
import pImage7Mobile from '../images/item-7-mobile.png';
import pImage8Mobile from '../images/item-8-mobile.png';
import Footer from "../components/Footer";
import AboveTheFold from "./AboveTheFold";
import useOnScreen from "../hooks/useOnScreen";

function HomePage() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const isInView1 = useOnScreen(ref1);
    const isInView2 = useOnScreen(ref2);
    const isInView3 = useOnScreen(ref3);
    const isInView4 = useOnScreen(ref4);
    const isInView5 = useOnScreen(ref5);
    const isInView6 = useOnScreen(ref6);
    const isInView7 = useOnScreen(ref7);
    const isInView8 = useOnScreen(ref8);


    useEffect(() => {
        mixpanel.track('view home page');
    }, []);

    const getPortfolioImage = (index) => {
        const isMobile = (window.innerWidth < 500);
        const desktop = [
            pImage1,
            pImage2,
            pImage3,
            pImage4,
            pImage5,
            pImage6,
            pImage7,
            pImage8,
        ];
        const mobile = [
            pImage1Mobile,
            pImage2Mobile,
            pImage3Mobile,
            pImage4Mobile,
            pImage5Mobile,
            pImage6Mobile,
            pImage7Mobile,
            pImage8Mobile,
        ];

        return (isMobile ? mobile : desktop)[index];
    };

    const getPortfolioHover = (index) => {
        const isMobile = (window.innerWidth < 500);
        const desktop = [
            pImage1Hover,
            pImage2Hover,
            pImage3Hover,
            pImage4Hover,
            pImage5Hover,
            pImage6Hover,
            pImage7Hover,
            pImage8Hover,
        ];
        const mobile = [
            pImage1Mobile,
            pImage2Mobile,
            pImage3Mobile,
            pImage4Mobile,
            pImage5Mobile,
            pImage6Mobile,
            pImage7Mobile,
            pImage8Mobile,
        ];

        return (isMobile ? mobile : desktop)[index];
    };

    return (
        <div>
            <AboveTheFold />
            <div className={'home-root'} >
            <div className="portfolio mt-10 md:mt-20 main-container">
                <div className="row">
                    <div className="child project-fade-in" ref={ref1}><PortfolioItem url={'/koala-vet-portal-redesign'} image={getPortfolioImage(0)} hover={getPortfolioHover(0)} /></div>
                    <div className="child project-fade-in" ref={ref2}><PortfolioItem url={'/order-history'} image={getPortfolioImage(1)} hover={getPortfolioHover(1)} /></div>
                </div>

                <div className="row">
                    <div className="child project-fade-in full-width" ref={ref3}><PortfolioItem url={'/self-sign-up'} image={getPortfolioImage(2)} hover={getPortfolioHover(2)} /></div>
                </div>

                <div className="row">
                    <div className="child project-fade-in" ref={ref4}><PortfolioItem url={'/telehealth-funnel'} image={getPortfolioImage(3)}
                                                          hover={getPortfolioHover(3)}/></div>
                    <div className="child project-fade-in" ref={ref5}><PortfolioItem url={'/seo-optimization'} image={getPortfolioImage(4)}
                                                          hover={getPortfolioHover(4)}/></div>
                </div>

                <div className="row">
                    <div className="child project-fade-in full-width" ref={ref6}><PortfolioItem url={'/pet-care-plan'}
                                                                     image={getPortfolioImage(5)}
                                                                     hover={getPortfolioHover(5)}/></div>
                </div>
                <div className="row">
                    <div className="child project-fade-in" ref={ref7}><PortfolioItem url={'/sendsio'} image={getPortfolioImage(6)}
                                                          hover={getPortfolioHover(6)}/></div>
                    <div className="child project-fade-in" ref={ref8}><PortfolioItem url={'/food-with-friends'} image={getPortfolioImage(7)}
                                                          hover={getPortfolioHover(7)}/></div>
                </div>
            </div>
            <Footer selected={'home'} />
            </div>
        </div>
    );
}

export default HomePage;
