import {useEffect, useRef} from 'react';
import mixpanel from 'mixpanel-browser';

import Header from "../components/Header";
import Footer from "../components/Footer";

import '../css/contact.css'
import useOnScreen from "../hooks/useOnScreen";

function Contact() {
    const ref1 = useRef(null);
    const isInView1 = useOnScreen(ref1);

    useEffect(() => {
        mixpanel.track('view contact');
    }, []);

    return (
        <div className="main-container">
            <Header selected={'contact'}></Header>
            <div className={'fade-in-fast'} ref={ref1}>
                <div className={'lets-talk'} >
                    Let's Talk
                </div>
                <div className={'contact-desc'}>
                    Looking for a Product Designer to help elevate your product? Want to chat about design process? Need a second pair of eyes on a design? Let’s have a virtual coffee and chat!
                </div>
                <a href={'mailto:balsaramichelle@gmail.com'} className={'custom-button'}>
                    Send Michelle an email
                </a>
            </div>
            <Footer selected={"contact"}/>
        </div>
    );
}

export default Contact;
